import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => {
  const { ...restOfProps } = props;

  return (
    <svg viewBox="0 0 1024 1024" width="20" height="22" {...restOfProps}>
      <path
        d="M121.5 458.5C70 458.5 28 415.2 28 362.1V116.5c0-53.1 42-96.4 93.5-96.4h237.7c51.5 0 93.4 43.2 93.4 96.4V362c0 53.2-41.9 96.5-93.4 96.5H121.5z m0-358c-8.6 0-15.6 7.2-15.6 16V362c0 8.7 7.1 16 15.6 16h237.7c8.4 0 15.5-7.3 15.5-16V116.5c0-8.8-7-16-15.5-16H121.5zM662.8 458.5c-51.6 0-93.6-43.3-93.6-96.5V116.5c0-53.1 42-96.4 93.6-96.4h237.7c51.5 0 93.5 43.2 93.5 96.4V362c0 53.2-41.9 96.5-93.5 96.5H662.8z m0-358c-8.5 0-15.6 7.3-15.6 16V362c0 8.7 7.2 16 15.6 16h237.7c8.5 0 15.6-7.3 15.6-16V116.5c0-8.8-7-16-15.6-16H662.8zM121.5 1007.5C70 1007.5 28 964.2 28 911V665.3c0-53.1 42-96.4 93.5-96.4h237.7c51.5 0 93.4 43.2 93.4 96.4V911c0 53.2-41.9 96.5-93.4 96.5H121.5z m0-358.2c-8.5 0-15.6 7.3-15.6 16V911c0 8.7 7.1 16.1 15.6 16.1h237.7c8.4 0 15.5-7.4 15.5-16.1V665.3c0-8.7-7.1-16-15.5-16H121.5zM662.8 1007.5c-51.6 0-93.6-43.3-93.6-96.5V665.3c0-53.1 42-96.4 93.6-96.4h237.7c51.5 0 93.5 43.2 93.5 96.4V911c0 53.2-41.9 96.5-93.5 96.5H662.8z m0-358.2c-8.5 0-15.6 7.3-15.6 16V911c0 8.7 7.2 16.1 15.6 16.1h237.7c8.5 0 15.6-7.4 15.6-16.1V665.3c0-8.7-7.2-16-15.6-16H662.8z"
        strokeWidth="16"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};
