import React from 'react';
import { useTranslation } from 'react-i18next';
import api from 'api';
import Table from '../../components/Table';
import usePaginatedItems from '../hooks/usePaginatedItems';
import useLabels from './hooks/useLabels';
import useQueryParams from 'hooks/useQueryParams';

import '../ManageUsers/ManageUsers.styles.scss';

const ManageAdmins: React.FC = () => {
  const { t } = useTranslation();
  const labels = useLabels();

  const {
    params: { page, limit },
  } = useQueryParams<{ page: string; limit: string }>();

  const { items, isFetching, totalItems, totalPages } = usePaginatedItems({
    queryKey: 'admins',
    makeRequest: api.admins.getAdmins,
    refetchOnMount: 'always',
    params: {
      limit,
      page,
    },
  });

  return (
    <Table
      fetching={isFetching}
      title={t('admins')}
      labels={labels}
      elementProps={labels.map(({ key }) => key)}
      contentArray={items}
      totalPages={totalPages}
      totalItems={totalItems}
    />
  );
};

export default ManageAdmins;
