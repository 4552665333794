import { useQuery } from '@tanstack/react-query';
import api from 'api';
import { BEProduct } from 'models/Products';
import { useEffect, useState } from 'react';
import utils from 'utils';

export default (id: string) => {
  const [product, setProduct] = useState<BEProduct>();

  const {
    isLoading,
    error,
    data: queryData,
  } = useQuery({
    queryKey: ['product', id],
    cacheTime: utils.minutesInMiliseconds(15),
    staleTime: utils.minutesInMiliseconds(15),
    queryFn: () => api.products.getProductById(id),
  });

  useEffect(() => {
    setProduct(queryData?.data);
  }, [queryData, product]);

  return {
    product,
    isLoading,
    error,
  };
};
