import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'models/User';
import useLabels from './hooks/useLabels';
import api from 'api';
import { toast } from 'react-toastify';
import Table from '../../components/Table';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import Modal from 'components/Modal';
import ManageUsersForm from './components/ManageUsersForm';
import { ManageUsersFormBody } from 'models/Associates';
import usePaginatedItems from '../hooks/usePaginatedItems';
import confirm from 'modules/confirm';
import utils from 'utils';
import useQueryParams from 'hooks/useQueryParams';
import { useQueryClient } from '@tanstack/react-query';
import { AssociateSearchBy, associateSearchByOptions } from 'types';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import { StaplesUser } from 'api/associates';
import { Link } from 'react-router-dom';

import './ManageUsers.styles.scss';

const ManageUsers: React.FC = () => {
  const { t } = useTranslation();
  const labels = useLabels();
  const queryClient = useQueryClient();

  const [associateId, setAssociateId] = useState<number>(null);

  const {
    setQueryParam,
    params: { page, search, limit, searchBy },
  } = useQueryParams<{
    page: string;
    search: string;
    limit: string;
    searchBy: AssociateSearchBy;
  }>({ searchBy: 'name' });

  const { items, isRefreshing, isFetching, totalItems, onRefresh, totalPages } =
    usePaginatedItems({
      queryKey: 'associates',
      makeRequest: api.associates.getAssociates,
      refetchOnMount: 'always',
      searchParam: searchBy,
      params: {
        limit,
        page,
        [searchBy]: !!search && search?.length >= 2 ? search : undefined,
      },
    });

  useEffectSkipFirst(() => {
    page !== '1' && setQueryParam('page', '1', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, limit]);

  const searchByOptions = useMemo(
    () =>
      Object.values(associateSearchByOptions).map(({ value, label }) => ({
        label: t(`${label}`),
        value,
      })),
    [t],
  );

  const modalRef = useRef<ModalRef>();

  const renderContentArray = useCallback(
    (data: StaplesUser[]) =>
      data?.map(({ name, id, ...rest }) => ({
        nameLink: (
          <Link className="associate-link" to={`/associate/${id}`}>
            {name}
          </Link>
        ),
        id,
        name,
        ...rest,
      })),
    [],
  );

  const deleteAssociate = useCallback(
    async (id: string) => {
      try {
        await api.associates.deleteAssociate(id);
        toast.success(t('successDeletingAssociate'));
        onRefresh();
        queryClient.invalidateQueries({
          queryKey: ['associates'],
        });
      } catch (error) {
        toast.error(t('General.error'));
      }
    },
    [onRefresh, queryClient, t],
  );

  const handleDeleteAssociate = useCallback(
    async (values: Partial<User>) => {
      const confirmDeletion = confirm({
        title: t('deleteAssociateTitle'),
        confirmBtnText: t('Delete'),
        content: t('deleteAssociate', {
          name: values?.name,
        }),
      });

      if (await confirmDeletion) {
        deleteAssociate(values?.id);
      }
    },
    [deleteAssociate, t],
  );

  const handleSearchByChange = useCallback(
    (option: OptionValue<any>) => {
      setQueryParam('searchBy', option.value, true);
    },
    [setQueryParam],
  );

  const addAssociate = useCallback(
    async (values: ManageUsersFormBody) => {
      try {
        await api.associates.addAssociate(values);
        toast.success(t('successAddingAssociate'));
        modalRef.current.close();
        setAssociateId(null);
        queryClient.invalidateQueries({
          queryKey: ['associates'],
        });
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      }
    },
    [queryClient, t],
  );

  const updateAssociate = useCallback(
    async (values: Partial<ManageUsersFormBody>, id: number) => {
      try {
        await api.associates.updateAssociate(values, id);
        toast.success(t('successUpdatingAssociate'));
        modalRef.current.close();
        setAssociateId(null);
        queryClient.invalidateQueries({
          queryKey: ['associates'],
        });
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      }
    },
    [queryClient, t],
  );

  const onSubmit = useCallback(
    async (values: ManageUsersFormBody, id?: number) => {
      if (values.storeNo) {
        values.storeNo = +values.storeNo;
      }
      values.associateId = +values.associateId;
      if (id) {
        updateAssociate(values, id);
      } else {
        addAssociate(values);
      }
    },
    [addAssociate, updateAssociate],
  );

  return (
    <>
      <Modal
        focusableElements="div"
        className="manage-users__modal"
        type="no-action"
        ref={modalRef}
        modalName="manage-associate"
        onClose={() => setAssociateId(null)}
        onX={() => {
          setAssociateId(null);
          modalRef.current.close();
        }}
        title={associateId ? t('editAssociate') : t('newAssociate')}
      >
        <ManageUsersForm
          inProgress={isRefreshing}
          onSubmit={(values) => onSubmit(values, associateId)}
          handleCancel={() => {
            setAssociateId(null);
            modalRef.current.close();
          }}
          associateId={associateId}
        />
      </Modal>
      <Table
        className="manage-users__table"
        fetching={isFetching}
        title={t('associates')}
        labels={labels}
        elementProps={labels.map(({ key }) => key)}
        contentArray={renderContentArray(items)}
        onDelete={(user) => handleDeleteAssociate(user)}
        buttonText={t('addAssociate')}
        onEdit={(el) => {
          setAssociateId(+el.id);
          modalRef.current.open();
        }}
        onAddButtonClick={() => modalRef.current.open()}
        withActionButtons
        totalItems={totalItems}
        totalPages={totalPages}
        searchable
        limit={limit}
        searchByOptions={searchByOptions}
        searchBy={searchBy}
        onSearchByChange={handleSearchByChange}
        searchPlaceholder={t('searchAssociatePlaceholder')}
      />
    </>
  );
};

export default ManageUsers;
