import { useCallback, useEffect, useState } from 'react';
import { User } from 'models/User';
import api from 'api';

const useAssociate = (
  id: number,
  admin = false,
): {
  associate?: User;
  loading?: boolean;
} => {
  const [loading, setLoading] = useState(true);
  const [associate, setAssociate] = useState<User>(null);

  const getAssociate = useCallback(async () => {
    try {
      setLoading(true);
      if (id) {
        const { data } = admin
          ? await api.admins.getAdmin(id)
          : await api.associates.getAssociate(id);
        setAssociate(data);
      } else return;
    } catch (err) {
      console.error();
    } finally {
      setLoading(false);
    }
  }, [admin, id]);

  useEffect(() => {
    getAssociate();
  }, [getAssociate]);

  return {
    associate,
    loading,
  };
};

export default useAssociate;
