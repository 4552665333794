import Axios from 'axios';
import env from '../env';

const publicHttpClient = Axios.create({
  baseURL: `${env.LOCAL_API_URL}/`,
});

publicHttpClient.interceptors.request.use(async function (config) {
  config.baseURL = env.LOCAL_API_URL;

  return config;
});

export default publicHttpClient;
