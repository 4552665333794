import { Loader } from 'ncoded-component-library';
import React from 'react';
import * as ReactDOM from 'react-dom/client';

// Spinner component with overlay
// it is rendered in parent component
// default postion is in center of the parent
// IMPORTANT: developer must be aware of hiding OverlaySpinner:
// for every show method there must be corresponding hide method called

export default {
  show: (
    parent: HTMLElement | string,
    label?: string,
    position?: 'center' | 'start' | 'end' | 'fixed',
    element: JSX.Element = <Loader text={label} color="#007cb9" />,
  ) => {
    let actualParent;

    if (typeof parent === 'string') {
      actualParent = document.querySelector(parent);
    } else {
      actualParent = parent;
    }

    if (!actualParent) {
      return;
    }

    const overlay = document.createElement('div');

    overlay.classList.add('overlay');

    position && overlay.classList.add(`position-${position}`);

    const overlaySpinnerId =
      actualParent?.getAttribute('overlay-id') || `id-${Date.now()}`;
    overlay.classList.add(overlaySpinnerId);

    if (actualParent) {
      actualParent.setAttribute('overlay-id', overlaySpinnerId);
      actualParent.classList.add('position-relative');
      actualParent.appendChild(overlay);

      const rootEl = ReactDOM.createRoot(overlay);
      rootEl.render(element);
    }
  },
  hide: (parent: HTMLElement | string) => {
    let actualParent;

    if (typeof parent === 'string') {
      actualParent = document.querySelector(parent);
    } else {
      actualParent = parent;
    }

    if (actualParent) {
      const parentOverlaySpinnerId = actualParent.getAttribute('overlay-id');

      if (parentOverlaySpinnerId) {
        const overlay = document.querySelector(`.${parentOverlaySpinnerId}`);

        actualParent.classList.remove('position-relative');

        overlay && actualParent.removeChild(overlay);
      }
    }
  },
};
