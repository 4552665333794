import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

const GoLeftIcon: React.FC<IconProps> = (props) => (
  <svg width="7" height="12" viewBox="0 0 7 12" fill="black" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.50596 0.410826C6.8314 0.736263 6.8314 1.2639 6.50596 1.58934L2.09522 6.00008L6.50596 10.4108C6.8314 10.7363 6.8314 11.2639 6.50596 11.5893C6.18053 11.9148 5.65289 11.9148 5.32745 11.5893L0.327452 6.58934C0.00201478 6.2639 0.00201478 5.73626 0.327452 5.41083L5.32745 0.410826C5.65289 0.0853888 6.18053 0.0853888 6.50596 0.410826Z"
    />
  </svg>
);

export default GoLeftIcon;
