import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import ResetPasswordForm from './components/ResetPasswordForm';
import './ResetPassword.styles.scss';
import './ResetPassword.styles.responsive.scss';
import StaplesLogo from 'icons/StaplesLogo.icon';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { Loader } from 'ncoded-component-library';

type ResetPasswordProps = {
  className?: string;
};

const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
  const { className } = props;

  const { currentUser, resetPassword, loading } =
    useContext(CurrentUserContext);

  const classes = classNames('hhsa-reset-password', className);

  const initialValues = useMemo(
    () => ({ userId: currentUser?.id }),
    [currentUser?.id],
  );

  return (
    <div className={classes}>
      {loading ? (
        <Loader color="#007cb9" />
      ) : (
        <>
          <a href="https://www.staples.ca/" target="_blank" rel="noreferrer">
            <StaplesLogo className="hhsa-reset-password__logo" />
          </a>
          <ResetPasswordForm
            initialValues={initialValues}
            onSubmit={resetPassword}
            inProgress={loading}
          />
        </>
      )}
    </div>
  );
};

export default ResetPassword;
