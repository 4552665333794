import React, { useCallback } from 'react';
import classNames from 'classnames';

import './FileInput.styles.scss';

export type FileInputProps = {
  trigger?: React.ReactNode;
  onChange?: (files: File[]) => void;
  value?: File[];
  inputProps?: React.HTMLAttributes<HTMLInputElement>;
  maxFiles?: number;
} & Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'value' | 'onChange'
>;

const FileInput: React.FC<FileInputProps> = (props) => {
  const {
    trigger,
    onChange,
    className,
    value = [],
    inputProps,
    maxFiles,
    ...restOfProps
  } = props;

  const classes = classNames(
    'hhsa-file-input',
    { filled: !!value?.length },
    className,
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;

      let newFiles = [...value, ...Array.from(files)];

      if (maxFiles && newFiles.length > maxFiles) {
        newFiles = newFiles.slice(0, maxFiles);
      }

      onChange(newFiles);
    },
    [maxFiles, onChange, value],
  );

  return (
    <div className="hhsa-file-dropzone">
      <label className={classes}>
        <input
          type="file"
          hidden
          onChange={handleOnChange}
          {...restOfProps}
          {...inputProps}
        />

        {trigger}
      </label>
    </div>
  );
};

export default FileInput;
