import { useQuery } from '@tanstack/react-query';
import api from 'api';
import classNames from 'classnames';
import CopyIcon from 'icons/Copy.icon';
import { Button, Loader, Select } from 'ncoded-component-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import HttpError from 'components/HttpError';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import { useInfinitePaginatedItems } from '../hooks/useInfinitePaginatedItems';

import './Checksums.styles.scss';

const checksumFormatter = (value: number) => value.toString().replace('.', '');

type ChecksumsProps = {
  className?: string;
};

const Checksums: React.FC<ChecksumsProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const classes = useMemo(() => classNames(className, 'checksum'), [className]);

  const [storeNo, setStoreNo] = useState<string | undefined>();

  const copyToClipboardAsync = useCallback(
    (text: string) => {
      navigator.clipboard
        .writeText(text)
        .then(function () {
          toast.success(t('Checksums.copiedToClipboard'));
        })
        .catch(function () {
          toast.error(t('Checksums.copiedToClipboardError'));
        });
    },
    [t],
  );

  const {
    isLoading,
    error,
    refetch,
    data: checksumData,
  } = useQuery({
    queryKey: ['checksums', storeNo],
    enabled: !!storeNo,
    queryFn: () => api.admins.getChecksums(storeNo && +storeNo),
  });

  const {
    items: stores,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfinitePaginatedItems({
    queryKey: 'stores',
    makeRequest: api.stores.getStores,
    params: {
      limit: '250',
    },
  });

  // Fetch all stores
  useEffect(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching]);

  const storeOptions = useMemo(() => {
    if (!stores) return [];

    return Object.values(stores).map(({ storeNo, name }) => ({
      label: name,
      value: storeNo.toString(),
    }));
  }, [stores]);

  if (error) {
    return <HttpError error={error} onTryAgain={refetch} />;
  }

  return (
    <div className={classes}>
      <h1>{t('Checksums.checksums')}</h1>
      <div className="checksum__select-store">
        <label>{t('store')}</label>

        <Select
          searchable
          name="language"
          className="hhsa-dropdown-select"
          options={storeOptions}
          value={storeNo}
          onChange={(option: OptionValue<string | number>) =>
            setStoreNo(option.value.toString())
          }
        />
      </div>

      <dl className="checksum__list">
        <div className="checksum__list__item">
          <dt>{t('storeNumber')}</dt>
          <dd>
            {!storeNo ? (
              '-'
            ) : isLoading ? (
              <Loader inline color="#007cb9" />
            ) : (
              checksumData?.store_no
            )}
          </dd>
        </div>
        <div className="checksum__list__item">
          <dt>{t('Checksums.count')}</dt>
          <dd>
            {!storeNo ? (
              '-'
            ) : isLoading ? (
              <Loader inline color="#007cb9" />
            ) : (
              <>{checksumData?.cnt}</>
            )}
          </dd>
        </div>
        <div className="checksum__list__item checksum__price-sum-item">
          <dt>{t('Checksums.priceSum')}</dt>
          <dd>
            {!storeNo ? (
              '-'
            ) : isLoading ? (
              <Loader inline color="#007cb9" />
            ) : (
              <>
                <Button
                  variant="icon"
                  className="copy-btn"
                  title="copy"
                  onClick={() =>
                    copyToClipboardAsync(
                      checksumFormatter(checksumData?.priceSum),
                    )
                  }
                >
                  <CopyIcon />
                </Button>
                <span>{checksumFormatter(checksumData?.priceSum)}</span>
              </>
            )}
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default Checksums;
