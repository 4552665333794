import httpClient from '../httpClient';
import { User } from 'models/User';
import { PaginatedReponse } from 'models';
import { ManageUsersFormBody } from 'models/Associates';
import {
  Pagination,
  RequestParams,
  Searchable,
} from 'router/subrouters/Dashboard/pages/hooks/usePaginatedItems';

class SearchUser implements Searchable {
  keys() {
    return ['name', 'associateId'] as const;
  }
}

export type StaplesUser = SearchUser & User;

const usersPath = (routePath: TemplateStringsArray | string) =>
  `users/${routePath}`;

const getAssociates = async (
  params: RequestParams<StaplesUser>,
): Promise<Pagination<StaplesUser>> => {
  return httpClient
    .get<PaginatedReponse<StaplesUser>>(usersPath``, { params })
    .then((response) => response.data);
};

const getAssociate = (id: number) => {
  return httpClient.get<User>(usersPath(`id/${id}`));
};

const deleteAssociate = (id: string) => {
  return httpClient.delete(usersPath(id));
};

const addAssociate = (associate: ManageUsersFormBody) => {
  return httpClient.post(usersPath``, associate);
};

const updateAssociate = (
  associate: Partial<ManageUsersFormBody>,
  id: number,
) => {
  return httpClient.patch<User>(usersPath(`${id}/update`), associate);
};

export default {
  getAssociates,
  deleteAssociate,
  getAssociate,
  addAssociate,
  updateAssociate,
};
