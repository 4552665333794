import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Select } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { LANGUAGES, LanguageKeys, languageOptions } from 'types';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import storageService from 'services/storageService';
import confirm from 'modules/confirm';
import '../SelectField/DropdownSelect.styles.scss';
import { useQueryClient } from '@tanstack/react-query';

import './LanguageDropdownSelect.styles.scss';

export type LanguageDropdownSelectProps = {
  className?: string;
  isClientOnly?: boolean;
};

const LanguageDropdownSelect: React.FC<LanguageDropdownSelectProps> = (
  props,
) => {
  const { className, isClientOnly = false } = props;
  const { t } = useTranslation();
  const selectedLanguageCode = i18next.language as LanguageKeys;
  const { currentUser, updateAdminInformation } =
    useContext(CurrentUserContext);

  const queryClient = useQueryClient();

  const countries = useMemo(
    () =>
      languageOptions.map(({ value, id }) => ({
        label: {
          icon: null,
          text: t(LANGUAGES[value as LanguageKeys]) as string,
        },
        searchValue: id,
        value,
        disabled: false,
      })),
    [t],
  );

  const setLanguage = useCallback(
    async (code: LanguageKeys) => {
      await storageService.setItem('language', code);
      await i18next.changeLanguage(code);

      if (isClientOnly || !currentUser) return;

      await updateAdminInformation({
        language: code,
      });
    },
    [currentUser, isClientOnly, updateAdminInformation],
  );

  const alertAboutLanguageSwitching = useCallback(
    async (code: LanguageKeys) => {
      if (code !== selectedLanguageCode) {
        if (
          await confirm({
            title: t('changeToLanguage', {
              language: t(LANGUAGES[code]),
            }),
            content: t('changeToLanguageSubtitle', {
              language: t(LANGUAGES[code]),
            }),
          })
        ) {
          setLanguage(code);

          queryClient.clear();
        }
      }
    },
    [queryClient, selectedLanguageCode, setLanguage, t],
  );

  const classes = classNames(
    'hhsa-dropdown-select',
    'language-dropdown',
    className,
  );

  return (
    <div className={classes}>
      <Select
        name="language"
        options={countries}
        value={selectedLanguageCode}
        onChange={({ value }: { value: LanguageKeys }) =>
          alertAboutLanguageSwitching(value)
        }
      />
    </div>
  );
};

export default LanguageDropdownSelect;
