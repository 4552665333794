import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import Flag, { FlagProps, FlagValue } from '../Flag/Flag.component';

type FieldCFlagProps = FlagProps & FieldRenderProps<FlagValue, HTMLElement>;

const FieldFlag = (props: FieldCFlagProps) => {
  const {
    input: { onChange, value },
    meta: { error, submitFailed },
    ...rest
  } = props;

  return (
    <>
      <Flag {...rest} value={value} onChange={onChange} />
      {error && submitFailed && <p className="field-error">{error}</p>}
    </>
  );
};

export default FieldFlag;
