import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from './components/Routes/Routes';
import Auth from './subrouters/Auth/Auth.router';
import authRoutes from './subrouters/Auth/routes';
import dashboardRoutes from './subrouters/Dashboard/routes';
import Dashboard from './subrouters/Dashboard/Dashboard.router';
import Profile from './subrouters/Dashboard/pages/Profile';
import ResetPassword from './subrouters/Auth/pages/ResetPassword';
import credentialsService from 'services/credentialsService';

export default [
  {
    path: 'auth',
    element: Auth,
    onlyPublic: true,
    routes: authRoutes,
  },

  {
    path: '/',
    element: Dashboard,
    authorized: true,
    routes: dashboardRoutes,
  },
  {
    path: 'profile',
    element: Profile,
    authorized: true,
    routes: dashboardRoutes,
  },
  {
    path: 'reset-password',
    element: ResetPassword,
    validate: () => !credentialsService.tmpToken,
  },
  {
    index: true,
    element: <Navigate to="/" replace />,
  },
  {
    path: '*',
    element: () => `not found`,
  },
] as Array<ComplexRoute>;
