import React, {
  ChangeEvent,
  JSXElementConstructor,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import useQueryParams from 'hooks/useQueryParams';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import utils from 'utils';

type RequiredProps = {
  defaultValue?: SimpleSelectValue;
  value: SimpleSelectValue;
  onChange: (val: SimpleSelectValue) => void;
};

export type SimpleSelectValue = string | string[];

type FilterProps<Props, MustProps extends RequiredProps> = {
  name: string;
  debounceOnChange?: number;
  defaultValue?: string;
  dbName?: string;
  onlyDefaultValue?: boolean;
  validate?: (value: string) => string;
  onDestroy?: () => void;
  valueMap?: (val: any) => string;
} & (Props extends {
  value?: any;
  onChange?: (val: any) => void;
}
  ? {
      component: JSXElementConstructor<Props>;
    } & Omit<Props, 'name' | 'component' | 'value' | 'onChange'>
  : {
      component: JSXElementConstructor<MustProps>;
    });

function Filter<Props, MustProps extends RequiredProps>(
  props: FilterProps<Props, MustProps>,
) {
  const isArray = props.name.includes('[]');

  const {
    name,
    dbName,
    defaultValue,
    valueMap = String,
    debounceOnChange,
    component,
    onDestroy,
    validate,
    ...compProps
  } = props;

  const { onlyDefaultValue = debounceOnChange > 0 } = props;

  const {
    params: { [name]: queryValue = '' },
    setQueryParam,
    removeQueryParam,
  } = useQueryParams({ [name]: defaultValue || '' });

  const value = useMemo(() => {
    if (!queryValue) return isArray ? [] : queryValue;
    return isArray ? queryValue.split(',') : queryValue;
  }, [isArray, queryValue]);

  const onChange = useCallback(
    <T extends { toString(): string }>(
      value: T | T[] | ChangeEvent<HTMLInputElement>,
    ) => {
      if (!value) {
        if (queryValue) {
          removeQueryParam(name);
        }
        return;
      }

      if (typeof value === 'number') {
        setQueryParam(name, value);
        return;
      }

      if ('target' in value) {
        const inputVal = value.target.value;

        if (inputVal) setQueryParam(name, inputVal);
        else removeQueryParam(name);
        return;
      }

      if (Array.isArray(value)) {
        if (!value.length) removeQueryParam(name);
        else setQueryParam(name, value.map(valueMap).join(','));
      } else {
        setQueryParam(name, valueMap(value));
      }
    },
    [name, queryValue, removeQueryParam, setQueryParam, valueMap],
  );

  const finalOnChange = useMemo(() => {
    if (!debounceOnChange) return onChange;

    return utils.debounce(onChange, debounceOnChange);
  }, [debounceOnChange, onChange]);

  useEffect(() => {
    if (!validate) return;
    if (isArray) return; // we'll  handle array validation later

    const correctedValue = validate(value as string);

    if (!queryValue && defaultValue) return; // if there is no queryValue but defaultValue is active that is okay

    // values differ - we need to correct it
    if (correctedValue !== queryValue) {
      setQueryParam(name, correctedValue, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, isArray, name, queryValue, validate, value]);

  useEffectSkipFirst(() => {
    return () => {
      onDestroy?.();
    };
  }, []);

  const Component = component;

  return (
    <Component
      {...(onlyDefaultValue ? { defaultValue: value } : { value })}
      onChange={finalOnChange}
      {...(compProps as unknown as any)}
    />
  );
}

export default Filter;
