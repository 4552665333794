import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useAssociate from '../ManageUsers/components/ManageUsersForm/hooks/useAssociate';
import { Loader } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import Avatar from 'components/Avatar';
import { useInfinitePaginatedItems } from '../hooks/useInfinitePaginatedItems';
import api from 'api';
import useQueryParams from 'hooks/useQueryParams';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';

import './Associate.styles.scss';
import './Associate.styles.responsive.scss';

ChartJS.register(ArcElement, Tooltip);

const Associate = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const containerRef = useRef(null);

  const { associate, loading: loadingAssociate } = useAssociate(+id);

  const {
    params: { types, startDate, endDate },
  } = useQueryParams<{
    page: string;
    types: string;
    timeFilter: string;
    startDate: string;
    endDate: string;
  }>();

  const {
    items,
    hasNextPage,
    fetchNextPage,
    isFetching: loadingActivities,
  } = useInfinitePaginatedItems({
    queryKey: 'activityLog',
    makeRequest: api.analytics.getActivity,
    params: {
      limit: '100',
      page: '1',
    },
    additionalParams: {
      userId: id,
      types: types?.split(', '),
      startDate,
      endDate,
    },
  });

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const handleScrollToBottom = () => {
      if (!hasNextPage || loadingActivities || items?.length === 0) return;

      fetchNextPage();
    };

    const handleScroll = () => {
      if (
        container.scrollHeight - container.scrollTop ===
        container.clientHeight
      ) {
        handleScrollToBottom();
      }
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [hasNextPage, fetchNextPage, loadingActivities, items]);

  return (
    <section className="associate">
      {loadingAssociate ? (
        <Loader color="#007cb9" inline />
      ) : (
        <>
          <article className="associate__info">
            <Avatar
              withInfo={false}
              className="associate__avatar"
              img={associate?.profileImageUrl}
            />

            <dl>
              <div className="associate__info__item">
                <dt>{t('name')}</dt>
                <dd>{associate?.name}</dd>
              </div>
              <div className="associate__info__item">
                <dt>{t('General.associateId')}</dt>
                <dd>{associate?.associateId}</dd>
              </div>
              <div className="associate__info__item">
                <dt>{t('General.storeNo')}</dt>
                <dd>{associate?.storeNo}</dd>
              </div>
              <div className="associate__info__item">
                <dt>{t('position')}</dt>
                <dd>{associate?.position}</dd>
              </div>
              <div className="associate__info__item">
                <dt>{t('moveInventoryStyle')}</dt>
                <dd>{associate?.inventoryScreenType}</dd>
              </div>
            </dl>
          </article>
        </>
      )}
    </section>
  );
};

export default Associate;
