import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import useSideBarItems from './hooks/useSideBarItems';

import './SideBar.styles.scss';
import './SideBar.styles.responsive.scss';

type SideBarProps = {
  className?: string;
};

const SideBar: React.FC<SideBarProps> = (props) => {
  const { className } = props;

  const classes = classNames('hhsa-side-bar', className);
  const sideBarItems = useSideBarItems();

  return (
    <aside className={classes}>
      <nav>
        {sideBarItems.map(({ path, label }) => {
          return (
            <NavLink key={path} to={path}>
              {label}
            </NavLink>
          );
        })}
      </nav>
    </aside>
  );
};

export default SideBar;
