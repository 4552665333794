import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;
const ValidationIcon = (props: IconProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 1024 1024"
      style={{ marginLeft: -3, marginRight: -4 }}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeWidth={50}
        d="M658.029714 441.837714a9.142857 9.142857 0 0 1 13.513143 12.324572l-161.810286 177.078857a9.142857 9.142857 0 0 1-13.220571 0.292571l-109.714286-110.171428a9.142857 9.142857 0 0 1 12.964572-12.909715l102.948571 103.387429 155.318857-170.002286z m-152.283428-215.222857a13.714286 13.714286 0 0 1 15.981714-0.109714c37.723429 26.605714 74.477714 48.036571 110.226286 64.292571 34.230857 15.561143 78.72 21.248 133.522285 16.768a13.714286 13.714286 0 0 1 14.811429 13.659429v230.674286c0 82.011429-38.857143 162.194286-116.406857 233.234285-53.558857 49.060571-102.162286 80.219429-146.157714 93.257143a13.714286 13.714286 0 0 1-9.106286-0.457143c-43.922286-18.048-91.099429-48.914286-141.659429-92.525714-74.514286-64.292571-127.689143-168.740571-127.689143-245.979429V329.947429c0-6.948571 5.193143-12.8 12.105143-13.622858 50.029714-5.906286 94.464-14.646857 133.302857-26.185142 37.997714-11.282286 78.372571-32.438857 121.069715-63.506286z m-113.261715 89.819429c-37.229714 11.062857-79.158857 19.602286-125.805714 25.636571V539.428571c0 68.809143 49.444571 165.869714 118.198857 225.206858 46.701714 40.301714 89.965714 68.955429 129.645715 86.016 38.326857-12.507429 82.048-41.051429 130.834285-85.76 72.118857-66.048 107.52-139.062857 107.52-212.992V335.908571c-52.553143 2.706286-96.64-3.931429-132.260571-20.114285-34.834286-15.835429-70.4-36.260571-106.678857-61.257143-42.203429 29.714286-82.651429 50.395429-121.453715 61.915428z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ValidationIcon;
