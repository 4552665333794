/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useMemo } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

export default function useQueryParams<
  Params extends { [K in keyof Params]?: string } = Record<string, string>,
>(defaultInit?: URLSearchParamsInit) {
  const [params, setParams] = useSearchParams(defaultInit);

  const paramsObj = useMemo(
    () => Object.fromEntries(params.entries()) as Params,
    [params],
  );

  const setQueryParam = useCallback(
    (key: keyof Params, value: string | number, replace = false) => {
      setParams(
        (oldParams) => {
          if (!value) oldParams.delete(key as string);
          else oldParams.set(key as string, value.toString());

          return oldParams;
        },
        { replace },
      );
    },
    [setParams],
  );

  const removeQueryParam = useCallback(
    (key: keyof Params, replace = false) => {
      setParams(
        (oldParams) => {
          oldParams.delete(key as string);

          return oldParams;
        },
        { replace },
      );
    },
    [setParams],
  );

  const resetQueryParams = useCallback(() => {
    setParams(defaultInit);
  }, [defaultInit, setParams]);

  return {
    params: paramsObj,
    setQueryParam,
    removeQueryParam,
    resetQueryParams,
  };
}
