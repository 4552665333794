import httpClient from 'api/httpClient';
import publicHttpClient from 'api/publicHttpClient';
import { Feature, FeatureChange } from 'models/Feature';
import type { FlagValue } from 'router/subrouters/Dashboard/pages/FeatureFlags/components/Flag/Flag.component';

type FlagValResp = {
  id: number;
  featureName: string;
  value: boolean | null;
  storeNumbers?: Array<number>;
  isStoreIncluded: boolean;
};

export type FlagValUpdate = {
  featureName: string;
} & Pick<FlagValue, 'excludeStoreNo' | 'includeStoreNo' | 'isEnabled'>;

const getFeatures = () =>
  publicHttpClient.get<Feature[]>('/toggle').then((response) => response.data);

const getStoreFeatures = () =>
  httpClient
    .get<Array<FlagValResp>>('/store-features-admin')
    .then(({ data }) => data);

const updateStoreFeatures = (flags: Array<FlagValUpdate>) =>
  httpClient.patch('/store-features-admin', flags);

const updateFeatures = (feature: FeatureChange) => {
  return httpClient.put<Feature[]>('/toggle', feature);
};

export default {
  getFeatures,
  updateFeatures,
  getStoreFeatures,
  updateStoreFeatures,
};
