import i18n, { Resource } from 'i18next';
import enTranslation from './en/translation.json';
import frTranslation from './fr/translation.json';
import { initReactI18next } from 'react-i18next';

const supportedLanguages = ['EN', 'FR'];

export const resources: Resource = {
  EN: {
    translation: enTranslation,
  },
  FR: {
    translation: frTranslation,
  },
} as const;

i18n.use({
  type: 'languageDetector',
  async: true,
  detect: (
    callback: (arg0: string | { language: string } | string[]) => void,
  ) => {
    const language = localStorage.getItem('language');
    if (!language) {
      // eslint-disable-next-line no-console
      console.log('No language is set, choosing English as fallback');
    } else {
      const finalLanguage = supportedLanguages?.includes(language)
        ? [language]
        : ['EN'];
      callback(finalLanguage);
      return;
    }
    callback(supportedLanguages);
  },

  cacheUserLanguage: (language: string) => {
    localStorage.setItem('language', language);
  },
});

i18n.use(initReactI18next).init({
  resources,
  debug: false,
  fallbackLng: 'EN',
  saveMissing: true,
});
