import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import api from 'api';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import Modal from 'components/Modal';
import useLabels from '../../../ProductCatalog/hooks/useLabels';
import ProductDetails from '../../../ProductCatalog/components/ProductDetails';
import Table from 'router/subrouters/Dashboard/components/Table';
import { useParams } from 'react-router-dom';
import usePaginatedItems from '../../../hooks/usePaginatedItems';
import useQueryParams from 'hooks/useQueryParams';
import { StaplesProduct } from 'models/Products';
import { parseVersion } from '../../../ProductCatalog/utils';
import { ProductSearchBy, productSearchByOptions } from 'types';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';

export type ProductProps = {
  id: string;
  imageUrl: string;
};

const StoreProducts: React.FC = () => {
  const { t } = useTranslation();
  const labels = useLabels();
  const modalRef = useRef<ModalRef>();
  const [productDetails, setProductDetails] = useState<ProductProps>(null);

  const { storeNo } = useParams();

  const {
    setQueryParam,
    params: { page, search, limit, searchBy },
  } = useQueryParams<{
    page: string;
    search: string;
    limit: string;
    searchBy: ProductSearchBy;
  }>({ searchBy: 'name' });

  const { items, isFetching, totalPages, totalItems } = usePaginatedItems({
    queryKey: 'store-products',
    makeRequest: api.products.getProductsByStore,
    searchParam: searchBy,
    params: {
      limit,
      page,
      [searchBy]: !!search && search.length >= 2 ? search : undefined,
      storeNo,
    },
  });

  const renderContentArray = useCallback((data: StaplesProduct[]) => {
    return data?.map(
      ({
        productCatalog: { imageUrls = [], name } = {},
        skuNo,
        version,
        id,
      }) => ({
        imageUrl: imageUrls[0],
        name,
        skuNo,
        id,
        lastUpdate: parseVersion(version),
      }),
    );
  }, []);

  const handleSearchByChange = useCallback(
    (option: OptionValue<any>) => {
      setQueryParam('searchBy', option.value, true);
    },
    [setQueryParam],
  );

  useEffect(() => {
    if (!productDetails?.id) {
      modalRef.current.close();
    }
  }, [productDetails?.id]);

  useEffect(() => {
    page !== '1' && setQueryParam('page', '1', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, limit]);

  const searchByOptions = useMemo(
    () =>
      Object.values(productSearchByOptions).map(({ value, label }) => ({
        label: t(`${label}`),
        value,
      })),
    [t],
  );

  return (
    <>
      <Modal
        focusableElements="div"
        type="no-action"
        ref={modalRef}
        modalName="store-product"
        onClose={() => setProductDetails(null)}
        onX={() => {
          setProductDetails(null);
          modalRef.current.close();
        }}
        title={t('productDetails')}
      >
        <ProductDetails
          id={productDetails?.id}
          img={productDetails?.imageUrl}
        />
      </Modal>
      <Table
        totalItems={totalItems}
        fetching={isFetching}
        title={t('storeProducts', { storeNo: storeNo })}
        labels={labels}
        elementProps={labels?.map(({ key }) => key)}
        onRowClick={(el) => {
          setProductDetails({ id: el.id, imageUrl: el.imageUrl });
          modalRef.current.open();
        }}
        cellComponentMap={{ imageUrl: 'ProductImage' }}
        searchable
        contentArray={renderContentArray(items)}
        totalPages={totalPages}
        limit={limit}
        searchBy={searchBy}
        searchByOptions={searchByOptions}
        onSearchByChange={handleSearchByChange}
        searchPlaceholder={t('searchProductPlaceholder')}
      />
    </>
  );
};

export default StoreProducts;
