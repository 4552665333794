import { useMemo } from 'react';
import {
  AdditionalParams,
  Item,
  Pagination,
  RequestParams,
  Searchable,
} from './usePaginatedItems';
import { useInfiniteQuery } from '@tanstack/react-query';

type PaginatedArgs<T extends Searchable> = {
  queryKey: string;
  enabled?: boolean;
  params: RequestParams<T>;
  additionalParams?: AdditionalParams;
  searchParam?: T extends Searchable ? ReturnType<T['keys']>[number] : string;
  makeRequest: (params: RequestParams<T>) => Promise<Pagination<T>>;
};

export function useInfinitePaginatedItems<T extends Item>(
  pagination: PaginatedArgs<T>,
) {
  const {
    params,
    additionalParams = {},
    makeRequest,
    queryKey = 'infiniteQuery',
    enabled = true,
  } = pagination;

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: [queryKey, ...Object.values(additionalParams)],
    enabled,
    refetchOnWindowFocus: false,
    queryFn: ({ pageParam }) =>
      makeRequest({
        ...params,
        ...additionalParams,
        page: (pageParam || 1).toString(),
      } as RequestParams<T>),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.currentPage === lastPage.totalPages) return undefined;
      return lastPage.currentPage + 1;
    },
  });

  const progress = useMemo(() => {
    return data?.pages?.length / data?.pages?.[0]?.totalPages || 0;
  }, [data?.pages]);

  return {
    items: data?.pages?.flatMap(({ items }) => items) || [],
    hasNextPage,
    isFetching,
    progress,
    fetchNextPage,
  };
}
