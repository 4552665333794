import { Env } from 'env';

export type ExtractPropTypes<Type> = Type extends React.FC<infer P> ? P : never;
export type ObjectValues<T> = T[keyof T];

export type Limit = '10' | '20' | '50' | '100' | '250';

export type SortDirection = 'ASC' | 'DESC';

export const limitOptions: {
  label: Limit;
  value: Limit;
}[] = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
];

export const maxLimitOptions: {
  label: Limit;
  value: Limit;
}[] = [
  {
    label: '250',
    value: '250',
  },
  ...limitOptions,
];

export const minLimitOptionValue = Math.min(
  ...limitOptions.map(({ value }) => +value),
);

export const languageOptions: {
  label: string;
  value: LanguageKeys;
  id: LanguageKeys;
}[] = [
  {
    label: 'GB',
    value: 'EN',
    id: 'EN',
  },
  {
    label: 'FR',
    value: 'FR',
    id: 'FR',
  },
];
export type Language = 'EN' | 'FR';

export type URLValue = {
  language: Language;
  url: string;
};

export enum LANGUAGES {
  EN = 'English',
  FR = 'Français',
}
export type MoveInventoryStyle = 'Drag and drop' | 'Radio button';

export const inventoryScreenType: {
  label: string;
  value: MoveInventoryStyle;
}[] = [
  {
    label: 'Drag-and-drop style',
    value: 'Drag and drop',
  },
  {
    label: 'Radio button style',
    value: 'Radio button',
  },
];

export type Positions =
  | 'Admin'
  | 'StoreOps'
  | 'DistrictManager'
  | 'Manager'
  | 'Associate';

export const positionOptions: {
  label: string;
  value: Partial<Positions>;
}[] = [
  {
    value: 'StoreOps',
    label: 'Store Ops',
  },
  {
    value: 'DistrictManager',
    label: 'District Manager',
  },
  {
    value: 'Manager',
    label: 'Manager',
  },
  {
    value: 'Associate',
    label: 'Associate',
  },
];

export const adminOptions: {
  label: string;
  value: Positions;
}[] = [
  {
    label: 'Admin',
    value: 'Admin',
  },
  ...positionOptions,
];

export const environmentOptions: {
  label: string;
  value: Env;
}[] = [
  {
    label: 'development',
    value: 'DEVELOPMENT',
  },
  {
    label: 'staging',
    value: 'STAGING',
  },
];

export type LanguageKeys = keyof typeof LANGUAGES;

export type ManageStoresFormType =
  | 'productUpload'
  | 'upcMapping'
  | 'addStore'
  | '';

export type ProductSearchBy = 'name' | 'skuNo';

export const productSearchByOptions: {
  label: string;
  value: ProductSearchBy;
}[] = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'SKU Number',
    value: 'skuNo',
  },
] as const;

export type StoreSearchBy = 'name' | 'storeNo';

export const storeSearchByOptions: {
  label: string;
  value: StoreSearchBy;
}[] = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Store Number',
    value: 'storeNo',
  },
] as const;

export type AssociateSearchBy = 'name' | 'associateId';

export const associateSearchByOptions: {
  label: string;
  value: AssociateSearchBy;
}[] = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Associate ID',
    value: 'associateId',
  },
] as const;

export type ActivityType =
  | 'AddToRestock'
  | 'RemoveFromRestock'
  | 'MoveInventory'
  | 'ReportInvalidPrice'
  | 'RemoveInvalidPrice'
  | 'ProductScanned';

export const activityTypeOptions: {
  label: string;
  value: ActivityType;
}[] = [
  {
    label: 'Add To Restock',
    value: 'AddToRestock',
  },
  {
    label: 'Remove From Restock',
    value: 'RemoveFromRestock',
  },
  {
    label: 'Move Inventory',
    value: 'MoveInventory',
  },
  {
    label: 'Report Invalid Price',
    value: 'ReportInvalidPrice',
  },
  {
    label: 'Remove Invalid Price',
    value: 'RemoveInvalidPrice',
  },
  {
    label: 'Product Scanned',
    value: 'ProductScanned',
  },
] as const;

export type TimeFilters = 'today' | 'lastWeek' | 'lastMonth' | 'custom';

export const timeFilterOptions: {
  label: string;
  value: TimeFilters;
}[] = [
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Last week',
    value: 'lastWeek',
  },
  {
    label: 'Last month',
    value: 'lastMonth',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
] as const;

export const activityColors = {
  'Add To Restock': 'green',
  'Remove From Restock': 'red',
  'Move Inventory': 'purple',
  'Report Invalid Price': 'yellow',
  'Remove Invalid Price': 'orange',
  'Product Scanned': 'blue',
};

export type AppVersion = {
  version: string;
  shouldMatchExactVersion: boolean;
};

export type Release = {
  id: number;
  short_version: string;
  version: string;
  uploaded_at: string;
  mandatory_update: boolean;
  enabled: boolean;
  is_external_build: boolean;
};
