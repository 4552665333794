import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../components/Table';
import usePaginatedItems from '../hooks/usePaginatedItems';
import api from 'api';
import useLabels from './hooks/useLabels';
import useQueryParams from 'hooks/useQueryParams';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import { StaplesProduct } from 'models/Products';
import { parseVersion } from './utils';
import { ProductSearchBy, productSearchByOptions } from 'types';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';

const ProductCatalog: React.FC = () => {
  const { t } = useTranslation();
  const labels = useLabels();

  const {
    setQueryParam,
    params: { page, search, limit, searchBy },
  } = useQueryParams<{
    page: string;
    search: string;
    limit: string;
    searchBy: ProductSearchBy;
  }>({ searchBy: 'name' });

  const { items, isFetching, totalPages, totalItems } = usePaginatedItems({
    queryKey: 'product-catalog',
    makeRequest: api.products.getProducts,
    searchParam: searchBy,
    params: {
      limit,
      page,
      [searchBy]: !!search && search?.length >= 2 ? search : undefined,
    },
  });

  const renderContentArray = useCallback((data: StaplesProduct[]) => {
    return data?.map(({ imageUrls = [], name, skuNo, version, upcs }) => ({
      imageUrl: imageUrls[0],
      name,
      skuNo,
      upc: upcs[0]?.code,
      id: `${skuNo}`,
      lastUpdate: parseVersion(version),
    }));
  }, []);

  const handleSearchByChange = useCallback(
    (option: OptionValue<any>) => {
      setQueryParam('searchBy', option.value, true);
    },
    [setQueryParam],
  );

  useEffectSkipFirst(() => {
    page !== '1' && setQueryParam('page', '1', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, limit]);

  const searchByOptions = useMemo(
    () =>
      Object.values(productSearchByOptions).map(({ value, label }) => ({
        label: t(`${label}`),
        value,
      })),
    [t],
  );

  return (
    <Table
      fetching={isFetching}
      title={t('productCatalog')}
      totalItems={totalItems}
      searchable
      labels={labels}
      elementProps={labels.map(({ key }) => key)}
      cellComponentMap={{ imageUrl: 'ProductImage' }}
      contentArray={renderContentArray(items)}
      totalPages={totalPages}
      limit={limit}
      searchBy={searchBy}
      searchByOptions={searchByOptions}
      onSearchByChange={handleSearchByChange}
      searchPlaceholder={t('searchProductPlaceholder')}
    />
  );
};

export default ProductCatalog;
