import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="80" height="80" viewBox="0 0 72 72" fill="none">
    <circle
      cx="31.5999"
      cy="29.2"
      r="25.2"
      stroke="#D9D9D7"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="13.5683"
      y1="47.1469"
      x2="0.778794"
      y2="60.0322"
      stroke="#D9D9D7"
      strokeWidth="1.101"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57 31.8H71V67.8H35V54.3"
      stroke="#D9D9D7"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56 25H27V54"
      stroke="#D9D9D7"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      opacity="0.2"
      x="50"
      y="57"
      width="6"
      height="6"
      rx="1"
      fill="#5d9fad"
    />
    <rect
      opacity="0.2"
      x="32"
      y="30"
      width="12"
      height="12"
      stroke="#5d9fad"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      opacity="0.2"
      x="60"
      y="57"
      width="6"
      height="6"
      rx="1"
      fill="#5d9fad"
    />
  </svg>
);
