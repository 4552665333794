import { maxLimitOptions } from 'types';
import utils from 'utils';

const correctedLimit = utils.DEFAULT_LIMIT.toString();

export function defaultValidateLimit(limit: string): string {
  // if it doesnt contain only digits return default limit

  if (!limit) return correctedLimit;

  if (!/^\d+$/.test(limit)) return correctedLimit;

  if (maxLimitOptions.every((opt) => opt.value !== limit)) {
    // limit doesn't match a single option that is in the options list
    return correctedLimit;
  }

  return limit;
}

export function defaultValidatePage(page: string): string {
  if (!page) return '1';
  // if it doesnt contain only digits return default page
  if (!/^\d+$/.test(page)) return '1';

  return page;
}
