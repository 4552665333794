import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import aisleOptixLogo from '../../../public/aisle-optix-logo.png';
import env from 'env';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import LanguageDropdownSelect from 'components/LanguageDropdownSelect';
import Burger from './components/Burger';

import './Header.styles.scss';
import './Header.styles.responsive.scss';

export type HeaderProps = {
  className?: string;
  rightComponent?: React.ReactNode;
};

const Header: React.FC<HeaderProps> = (props) => {
  const { className, rightComponent } = props;

  const [menuState, setMenuState] = useState<
    'closed' | 'opening' | 'closing' | 'open'
  >('closed');

  const classes = classNames('hhsa-header', className);
  const navClasses = classNames('hhsa-header__right-component', menuState);

  const { t } = useTranslation();

  const environment = env.currentEnv;

  return (
    <>
      <header className={classes}>
        <Link to="/manage-associates">
          <img src={aisleOptixLogo} width={180} alt="logo" />
        </Link>
        <div className="hhsa-header__menu-switcher">
          <Burger
            open={menuState === 'opening' || menuState === 'open'}
            onClick={() =>
              setMenuState((prev) =>
                prev === 'closed' || prev === 'closing' ? 'opening' : 'closing',
              )
            }
          />
        </div>
        <nav
          className={navClasses}
          onTransitionEnd={(ev) => {
            if ((ev.target as HTMLElement).nodeName.toLowerCase() === 'nav') {
              setMenuState((old) => (old === 'opening' ? 'open' : 'closed'));
            }
          }}
        >
          {env.accessDifferentEnvs && (
            <span className="hhsa-header__environment" id="env-identifier">
              {t(`${environment}`)}
            </span>
          )}
          <LanguageDropdownSelect />
          {rightComponent}
        </nav>
      </header>
      {env.accessDifferentEnvs && (
        <Tooltip
          anchorSelect="#env-identifier"
          content={t('currentEnv', { environment: t(`${environment}`) })}
        />
      )}
    </>
  );
};

export default Header;
