import React, { useContext } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StoreIcon } from '../../../icons/store.svg';
import { ReactComponent as UserIcon } from '../../../icons/users.svg';
import { ReactComponent as AdminIcon } from '../../../icons/admin.svg';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { ReactComponent as FlagIcon } from '../../../icons/flag.svg';
import ProductCatalogIcon from 'icons/ProductCatalog.icon';
import ValidationIcon from 'icons/Validation.icon';

export type SideBarItem = {
  path: string;
  label: string;
};

export default () => {
  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserContext);

  const adminItems = useMemo(
    () => [
      {
        path: '/manage-admins',
        label: (
          <>
            <span className="sidebar-icon">
              <AdminIcon />
            </span>
            <span>{t('admins')}</span>
          </>
        ),
      },
      {
        path: '/checksums',
        label: (
          <>
            <span>
              <ValidationIcon />
            </span>
            <span>{t('Checksums.checksums')}</span>
          </>
        ),
      },
      {
        path: '/feature-flags',
        label: (
          <>
            <span className="sidebar-icon">
              <FlagIcon />
            </span>
            <span>{t('featureFlags')}</span>
          </>
        ),
      },
      // {
      //   path: '/version-select',
      //   label: (
      //     <>
      //       <span className="sidebar-icon">
      //         <GearIcon />
      //       </span>
      //       <span>{t('versionSelect')}</span>
      //     </>
      //   ),
      // },
    ],
    [t],
  );

  const sideBarItems = useMemo(() => {
    const commonItems = [
      {
        path: '/manage-associates',
        label: (
          <>
            <span className="sidebar-icon">
              <UserIcon />
            </span>
            <span>{t('manageAssociates')}</span>
          </>
        ),
      },
      {
        path: '/manage-stores',
        label: (
          <>
            <span className="sidebar-icon">
              <StoreIcon />
            </span>
            <span>{t('manageStores')}</span>
          </>
        ),
      },
      {
        path: '/product-catalog',
        label: (
          <>
            <span className="sidebar-icon">
              <ProductCatalogIcon />
            </span>
            <span>{t('productCatalog')}</span>
          </>
        ),
      },
    ];

    return currentUser?.position === 'Admin'
      ? [...commonItems, ...adminItems]
      : commonItems;
  }, [adminItems, currentUser?.position, t]);

  return sideBarItems;
};
