import React, { useCallback, useMemo } from 'react';
import { RadioGroup } from 'ncoded-component-library';
import ReactDatePicker from 'react-datepicker';
import { TimeFilters, timeFilterOptions } from 'types';
import { useTranslation } from 'react-i18next';
import { SelectedFilters } from '../Filters/Filters.component';
import { DATES } from './utils';

import './DateFilter.styles.scss';
import 'react-datepicker/dist/react-datepicker.css';

type DateFilterProps = {
  selectedFilters: SelectedFilters;
  onFilterChange: (name: string, value: string) => void;
};

const DateFilter: React.FC<DateFilterProps> = ({
  selectedFilters,
  onFilterChange,
}) => {
  const start = selectedFilters['startDate']
    ? new Date(selectedFilters['startDate'])
    : null;
  const end = selectedFilters['endDate']
    ? new Date(selectedFilters['endDate'])
    : null;

  const { t } = useTranslation();

  const handleTimeFilterChange = useCallback(
    (option: TimeFilters) => {
      onFilterChange('timeFilter', option);
      onFilterChange('startDate', DATES[option]);

      if (option !== 'custom') {
        onFilterChange('endDate', '');
      }
    },
    [onFilterChange],
  );

  const onDateChange = useCallback(
    (dates: [Date, Date]) => {
      const [start, end] = dates;

      if (start) {
        const startOfDay = new Date(start);
        startOfDay.setHours(0, 0, 0, 0);
        onFilterChange('startDate', startOfDay.toISOString());
      }

      if (end) {
        const endOfDay = new Date(end);
        endOfDay.setHours(23, 59, 59, 999);
        onFilterChange('endDate', endOfDay.toISOString());
      }
    },
    [onFilterChange],
  );

  const timeOptions = useMemo(
    () =>
      Object.values(timeFilterOptions).map(({ value }) => ({
        label: t(`${value}`),
        value,
      })),
    [t],
  );

  return (
    <div className="date-filter">
      <label className="filters__filter__label">{t('timePeriod')}</label>
      <RadioGroup
        name="timeFilter"
        options={timeOptions}
        value={selectedFilters['timeFilter']}
        onChange={(ev) =>
          handleTimeFilterChange(ev.currentTarget.value as TimeFilters)
        }
      />

      {selectedFilters['timeFilter'] === 'custom' && (
        <div className="date-filter__date-picker">
          <ReactDatePicker
            onChange={onDateChange}
            maxDate={new Date()}
            startDate={start}
            endDate={end}
            dateFormat="yyyy/MM/dd"
            selectsRange
            startOpen
          />
        </div>
      )}
    </div>
  );
};

export default DateFilter;
