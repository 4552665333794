import React, { useMemo } from 'react';
import { RouteProps, Navigate, useLocation } from 'react-router-dom';
import credentialsService from 'services/credentialsService';
import { Role } from 'models/User';

export type EnhancedRouteProps = {
  authorized?: boolean;
  onlyPublic?: boolean;
  authorizedRoles?: Array<Role>;
  validate?: () => string;
} & Omit<RouteProps, 'element'> & {
    element: React.FC | React.ReactNode;
  };

const EnhancedRoute: React.FC<EnhancedRouteProps> = (props) => {
  const {
    authorized = false,
    onlyPublic = false,
    element,
    validate,
    authorizedRoles,
  } = props;

  const redirectInvalid = validate?.();
  const isLoggedIn = !!credentialsService.token;

  const location = useLocation();

  const finalRoute = useMemo(() => {
    if (((authorized || authorizedRoles) && !isLoggedIn) || redirectInvalid) {
      return (
        <Navigate
          to={{ pathname: '/auth/login' }}
          state={{ from: location.pathname }}
          replace
        />
      );
    }

    if (onlyPublic && isLoggedIn) {
      return <Navigate to="/" />;
    }

    if (typeof element === 'function') {
      const Component = element;

      return <Component />;
    }
    return element as React.ReactElement;
  }, [
    authorized,
    authorizedRoles,
    isLoggedIn,
    redirectInvalid,
    onlyPublic,
    element,
    location.pathname,
  ]);

  return finalRoute;
};

export default EnhancedRoute;
