import React from 'react';
import classNames from 'classnames';
import Filter from 'components/Filter';
import { Input } from 'ncoded-component-library';
import SearchIcon from 'icons/Search.icon';

import './Search.styles.scss';

type SearchProps = {
  searchName?: string;
  placeholder: string;
  className?: string;
};

const Search: React.FC<SearchProps> = (props) => {
  const { searchName = 'search', placeholder, className } = props;

  const classes = classNames('hhsa-search', className);

  return (
    <Filter
      name={searchName}
      className={classes}
      component={Input}
      dbName="search"
      type="search"
      debounceOnChange={300}
      prefixNode={<SearchIcon />}
      placeholder={placeholder}
    />
  );
};

export default Search;
