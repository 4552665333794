export const storeStatusType = {
  0: 'Disabled',
  1: 'Active',
};

export type Store = {
  id: string;
  storeNo: number;
  districtNo: number;
  name: string;
  address: string;
  city: string;
  province: string;
  postalCode: string;
  phoneNo: string;
  faxNo: string;
  statusId: 0 | 1;
  status: string;
};

export type ManageStoresFormBody = Omit<Store, 'id'>;
