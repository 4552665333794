import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import GoLeftIcon from 'icons/GoLeft.icon';
import GoRightIcon from 'icons/GoRight.icon';
import { useCallback } from 'react';

import './Pagination.styles.scss';

type PaginationProps = {
  className?: string;
  totalPages: number;
  value: number | string;
  onChange: (value: number) => void;
};

type PageProps = {
  active: boolean;
  page: number;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const Page = ({ active, onClick, page }: PageProps) => (
  <li
    className={classNames('hhsa-pagination__item', { active })}
    aria-label={`page ${page}`}
    {...(active && { 'aria-current': 'page' })}
  >
    <button onClick={onClick}>{page}</button>
  </li>
);

const SIDE_ITEMS_LENGTH = 2;
const SCOPE_SIZE = 5;

const Pagination: React.FC<PaginationProps> = (props) => {
  const { value: propValue, className, onChange, totalPages } = props;

  const value = +propValue;

  const classes = classNames('hhsa-pagination', className);

  const activeScope = useMemo(() => {
    if (totalPages <= SCOPE_SIZE + 2)
      return Array.from({ length: Math.max(totalPages - 2, 0) }).map(
        (_, i) => 2 + i,
      );

    const startingPage = Math.min(
      totalPages - SIDE_ITEMS_LENGTH,
      Math.max(1 + SIDE_ITEMS_LENGTH, value),
    );

    const numberOfLeftItems = Math.min(SIDE_ITEMS_LENGTH, startingPage - 1);
    const numberOfRightItems = Math.min(
      SIDE_ITEMS_LENGTH,
      Math.max(totalPages - 1 - startingPage, 0),
    );

    let arr = [];
    for (
      let page = startingPage - numberOfLeftItems;
      page <= startingPage + numberOfRightItems;
      page++
    ) {
      arr.push(page);
    }

    if (arr.includes(1)) arr = arr.slice(1);
    if (arr.includes(totalPages)) arr = arr.slice(0, -1);

    return arr;
  }, [value, totalPages]);

  const previousPage = (
    <li>
      <button
        onClick={() => onChange(value - 1)}
        disabled={value === 1}
        aria-label="Previous page"
      >
        <GoLeftIcon />
      </button>
    </li>
  );

  const showThreeDotsLeft = value - SIDE_ITEMS_LENGTH - 1 > 1;

  const threeDotsLeft = showThreeDotsLeft && (
    <li>
      <button
        onClick={() => onChange(activeScope[0] - 1)}
        aria-label="Previous 5 pages"
      >
        ...
      </button>
    </li>
  );

  const showThreeDotsRight = value + SIDE_ITEMS_LENGTH < totalPages - 1;

  const threeDotsRight = showThreeDotsRight && (
    <li>
      <button
        aria-label="Next 5 pages"
        onClick={() => onChange(activeScope.slice(-1)[0] + 1)}
      >
        ...
      </button>
    </li>
  );

  const nextPage = (
    <li>
      <button
        onClick={() => onChange(value + 1)}
        disabled={value === totalPages}
        aria-label="Next page"
      >
        <GoRightIcon />
      </button>
    </li>
  );

  const checkOnChange = useCallback(
    (page: number) => {
      if (value === page) return;

      onChange?.(page);
    },
    [onChange, value],
  );

  useEffect(() => {
    if (value > totalPages) {
      onChange(1);
    }
  }, [value, totalPages, onChange]);

  return (
    <nav aria-label="pagination" className={classes}>
      <ul>
        {previousPage}
        <Page onClick={() => checkOnChange(1)} active={value === 1} page={1} />
        {threeDotsLeft}
        {activeScope.map((page) => (
          <Page
            onClick={() => checkOnChange(page)}
            active={page === value}
            page={page}
            key={page}
          />
        ))}
        {threeDotsRight}
        {totalPages > 1 && (
          <Page
            onClick={() => checkOnChange(totalPages)}
            active={value === totalPages}
            page={totalPages}
          />
        )}
        {nextPage}
      </ul>
    </nav>
  );
};

export default Pagination;
