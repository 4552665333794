import React, { useCallback, useMemo, useRef, useState } from 'react';
import Table from '../../../../components/Table';
import { useTranslation } from 'react-i18next';
import useLabels from '../../hooks/useLabels';
import Modal from 'components/Modal';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import ManageStoresForm from '../../components/ManageStoresForm';
import { ManageStoresFormBody, storeStatusType } from 'models/Stores';
import { toast } from 'react-toastify';
import api from 'api';
import usePaginatedItems from '../../../hooks/usePaginatedItems';
import {
  ManageStoresFormType,
  StoreSearchBy,
  storeSearchByOptions,
} from 'types';
import UploadFileForm from '../../components/UploadFileForm';
import utils from 'utils';
import useQueryParams from 'hooks/useQueryParams';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import { StaplesStore } from 'api/stores';

import './ManageStores.styles.scss';

const ManageStores: React.FC = () => {
  const { t } = useTranslation();
  const labels = useLabels();
  const modalRef = useRef<ModalRef>();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [formType, setFormType] = useState<ManageStoresFormType>('');

  const {
    setQueryParam,
    params: { page, search, limit, searchBy },
  } = useQueryParams<{
    page: string;
    search: string;
    limit: string;
    searchBy: StoreSearchBy;
  }>({ searchBy: 'name' });

  const { items, isRefreshing, isFetching, onRefresh, totalItems, totalPages } =
    usePaginatedItems({
      queryKey: 'stores',
      makeRequest: api.stores.getStores,
      searchParam: searchBy,
      params: {
        limit,
        page,
        [searchBy]: !!search && search?.length >= 2 ? search : undefined,
      },
    });

  useEffectSkipFirst(() => {
    page !== '1' && setQueryParam('page', '1', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, limit]);

  const searchByOptions = useMemo(
    () =>
      Object.values(storeSearchByOptions).map(({ value, label }) => ({
        label: t(`${label}`),
        value,
      })),
    [t],
  );

  const addStore = useCallback(
    async (values: ManageStoresFormBody) => {
      try {
        await api.stores.addStore(values);
        toast.success(t('successAddingStore'));
        modalRef.current.close();
        queryClient.invalidateQueries({
          queryKey: ['stores'],
        });
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      }
    },
    [queryClient, t],
  );

  const uploadProducts = useCallback(
    async ({ attachment }: { attachment: File[] }) => {
      try {
        const formData = new FormData();
        formData.append('file', attachment[0]);
        await api.stores.uploadProducts(formData);
        toast.success(t('successUploadingProductsFile'));
        modalRef.current.close();
        setFormType('');
        onRefresh();
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      }
    },
    [onRefresh, t],
  );

  const uploadUPCMapping = useCallback(
    async ({ attachment }: { attachment: File[] }) => {
      try {
        const formData = new FormData();
        formData.append('file', attachment[0]);
        await api.stores.uploadUPCMapping(formData);
        toast.success(t('successUploadingUPCMappingFile'));
        modalRef.current.close();
        setFormType('');
        onRefresh();
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      }
    },
    [onRefresh, t],
  );

  const onUploadFile = useCallback(
    async ({ attachment }: { attachment: File[] }) => {
      if (formType === 'productUpload') {
        uploadProducts({ attachment });
      } else if (formType === 'upcMapping') {
        uploadUPCMapping({ attachment });
      }
    },
    [formType, uploadProducts, uploadUPCMapping],
  );

  const handleSearchByChange = useCallback(
    (option: OptionValue<any>) => {
      setQueryParam('searchBy', option.value, true);
    },
    [setQueryParam],
  );

  const renderContentArray = useCallback(
    (data: StaplesStore[]) =>
      data?.map(({ statusId, ...rest }) => ({
        ...rest,
        status: t(storeStatusType[statusId]),
      })),
    [t],
  );

  return (
    <>
      <Modal
        focusableElements="div"
        type="no-action"
        ref={modalRef}
        modalName="manage-stores"
        onX={() => {
          setFormType('');
          modalRef.current.close();
        }}
        title={
          formType === 'productUpload'
            ? t('uploadProductsFile')
            : formType === 'addStore'
            ? t('newStore')
            : t('uploadUPCMappingFile')
        }
      >
        {formType === 'addStore' ? (
          <ManageStoresForm
            onSubmit={addStore}
            inProgress={isRefreshing}
            onClose={() => setFormType('')}
            handleCancel={() => {
              modalRef.current.close();
            }}
          />
        ) : (
          <UploadFileForm
            onSubmit={onUploadFile}
            inProgress={isRefreshing}
            onClose={() => setFormType('')}
            handleCancel={() => {
              modalRef.current.close();
            }}
            fileType={formType === 'productUpload' ? '.dat' : '.map'}
          />
        )}
      </Modal>
      <Table
        className="manage-stores__table"
        keyName="storeNo"
        fetching={isFetching}
        totalItems={totalItems}
        title={t('stores')}
        labels={labels}
        elementProps={labels.map(({ key }) => key)}
        contentArray={renderContentArray(items)}
        buttonText={t('addStore')}
        onAddButtonClick={() => {
          setFormType('addStore');
          modalRef.current.open();
        }}
        onUploadProducts={() => {
          setFormType('productUpload');
          modalRef.current.open();
        }}
        onUploadUPCMapping={() => {
          setFormType('upcMapping');
          modalRef.current.open();
        }}
        totalPages={totalPages}
        onRowClick={({ storeNo }) => navigate(`/manage-stores/${storeNo}`)}
        searchable
        searchByOptions={searchByOptions}
        searchBy={searchBy}
        limit={limit}
        onSearchByChange={handleSearchByChange}
        searchPlaceholder={t('searchStorePlaceholder')}
      />
    </>
  );
};

export default ManageStores;
