import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { Button, Modal as ModalNC } from 'ncoded-component-library';
import { ExtractPropTypes } from 'types';
import { ModalRef as ModalRefNC } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { useLocation, useSearchParams } from 'react-router-dom';
import useCallbackRef from 'hooks/useCallbackRef';

import './Modal.styles.scss';
import './Modal.styles.responsive.scss';

const parseQueryParamToBoolean = (param: string) => {
  if (!param) return false;

  return param === 'open';
};

export type ModalRef = ModalRefNC;

type DefaultModalProps = Omit<
  ExtractPropTypes<typeof ModalNC>,
  'controlledByParent'
>;

type ModalTypes = 'confirm' | 'action' | 'no-action';

type ActionModal = {
  type: 'action';
  primaryActionContent: React.ReactNode;
  secondaryActionContent: React.ReactNode;
  cancelActionContent: React.ReactNode;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
};

type ConfirmModal = {
  type: 'confirm';
  confirmActionContent?: React.ReactNode;
};

type NoActionModal = {
  type: 'no-action';
};

type ModalProps = (ActionModal | ConfirmModal | NoActionModal) &
  DefaultModalProps & {
    hideHeader?: boolean;
    isFullscreen?: boolean;
    type: ModalTypes;
    modalName: string;
    primaryActionContent?: React.ReactNode;
    secondaryActionContent?: React.ReactNode;
    cancelActionContent?: React.ReactNode;
    addSearchParams?: boolean;
    onPrimaryAction?: () => void;
    onSecondaryAction?: () => void;
    confirmActionContent?: React.ReactNode;
    keepOpenOnRefresh?: boolean;
  };

const Modal: React.ForwardRefRenderFunction<ModalRefNC, ModalProps> = (
  props,
  ref,
) => {
  const {
    children,
    className,
    hideHeader = false,
    addSearchParams = true,
    footer,
    type,
    open,
    isFullscreen,
    modalName,
    title,
    onOpen,
    onClose,
    keepOpenOnRefresh = true,
    ...restOfProps
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  const isModalOpen =
    addSearchParams && keepOpenOnRefresh
      ? parseQueryParamToBoolean(searchParams.get(modalName))
      : open;

  const classes = classNames(
    'hhsa-modal',
    {
      'hhsa-modal--no-header': hideHeader,
      'hhsa-modal--confirm': type === 'confirm',
      'hhsa-modal--action': type === 'action',
      'hhsa-modal--fullscreen': isFullscreen,
    },
    className,
  );

  const openModal = useCallback(() => {
    if (!addSearchParams) {
      return onOpen?.();
    }

    searchParams.set(modalName, 'open');
    // We push a query param so that when we go 'back' the modal will close.
    // This is more mobile friend because modals can close with location 'back'.
    setSearchParams(searchParams, { state: location.state });

    onOpen?.();

    // return false so that the built in state
    // management in ncoded-library doesn't fire
    return false;
  }, [
    addSearchParams,
    location.state,
    modalName,
    onOpen,
    searchParams,
    setSearchParams,
  ]);

  const closeModal = useCallback(() => {
    if (!addSearchParams) {
      return onClose?.();
    }
    searchParams.delete(modalName);
    // 'replace: true' because we don't want
    // the modal opening when we go location 'back'
    setSearchParams(searchParams, { replace: true, state: location.state });

    onClose?.();

    // return false so that the built in state
    // management in ncoded-library doesn't fire
    return false;
  }, [
    addSearchParams,
    location.state,
    modalName,
    onClose,
    searchParams,
    setSearchParams,
  ]);

  useImperativeHandle(
    ref,
    () => ({
      open: openModal,
      close: closeModal,
      openState: isModalOpen,
      container: modal?.container,
    }),
    [closeModal, isModalOpen, modal, openModal],
  );

  const modalTitle = useMemo(
    () =>
      isFullscreen ? <div className="hhsa-modal__back">{title}</div> : title,
    [isFullscreen, title],
  );

  const modalFooter: React.ReactNode | undefined = useMemo(() => {
    if (typeof footer !== 'undefined') return footer;

    let footerContent;

    switch (type) {
      case 'confirm': {
        const { confirmActionContent = 'OK' } = restOfProps;

        footerContent = (
          <Button type="button" onClick={closeModal}>
            {confirmActionContent}
          </Button>
        );

        break;
      }

      case 'action': {
        const {
          primaryActionContent,
          secondaryActionContent,
          cancelActionContent,
          onPrimaryAction,
          onSecondaryAction,
        } = restOfProps;

        footerContent = (
          <>
            <div className="hhsa-modal--action__top-actions">
              <Button type="button" onClick={onPrimaryAction}>
                {primaryActionContent}
              </Button>
              <Button type="button" onClick={onSecondaryAction} variant="link">
                <span>{secondaryActionContent}</span>
              </Button>
            </div>
            {cancelActionContent && (
              <Button
                type="button"
                onClick={closeModal}
                variant="link"
                className="hhsa-modal--action__cancel-button"
              >
                {cancelActionContent}
              </Button>
            )}
          </>
        );

        break;
      }

      default:
        break;
    }

    return footerContent;
  }, [footer, type, restOfProps, closeModal]);

  return (
    <ModalNC
      className={classes}
      open={isModalOpen}
      onOpen={openModal}
      onClose={closeModal}
      controlledByParent
      footer={modalFooter}
      ref={modalRef}
      title={modalTitle}
      {...restOfProps}
    >
      {children}
    </ModalNC>
  );
};

export default forwardRef(Modal);
