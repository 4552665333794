import React from 'react';
import { Navigate } from 'react-router-dom';
import ManageUsers from './pages/ManageUsers';
import { ComplexRoute } from 'router/components/Routes/Routes';
import ManageStores from './pages/ManageStores/pages/ManageStores';
import ManageAdmins from './pages/ManageAdmins';
import FeatureFlags from './pages/FeatureFlags';
import ProductCatalog from './pages/ProductCatalog';
import StoreProducts from './pages/ManageStores/pages/StoreProducts';
// import ActivityLog from './pages/ActivityLog';
import Associate from './pages/Associate';
// import VersionSelect from './pages/VersionSelect';
import Checksums from './pages/Checksums/Checksums.page';

export default [
  {
    path: 'manage-associates',
    element: ManageUsers,
  },
  {
    path: 'manage-stores',
    element: ManageStores,
  },
  {
    path: 'manage-stores/:storeNo',
    element: StoreProducts,
  },
  {
    path: 'manage-admins',
    element: ManageAdmins,
  },
  {
    path: 'checksums',
    element: Checksums,
  },
  {
    path: 'feature-flags',
    element: FeatureFlags,
  },
  {
    path: 'product-catalog',
    element: ProductCatalog,
  },
  // {
  //   path: 'activity-log',
  //   element: ActivityLog,
  // },
  {
    path: 'associate/:id',
    element: Associate,
  },
  // {
  //   path: 'version-select',
  //   element: VersionSelect,
  // },
  {
    index: true,
    element: <Navigate to="manage-associates" replace />,
  },
] as Array<ComplexRoute>;
