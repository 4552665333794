import api from 'api';
import React, { useCallback, useEffect, useState } from 'react';
import CurrentUserContext from './CurrentUser.context';
import { User } from 'models/User';
import credentialsService from 'services/credentialsService';
import utils from 'utils';
import refreshTokenService from 'services/refreshTokenService';
import bus from 'modules/bus';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ResetPasswordFormValues } from 'models/AuthBody';
import OverlaySpinner from 'components/OverlaySpinner';
import i18next from 'i18next';

type CurrentUserProps = {
  children: React.ReactNode;
};

const CurrentUser: React.FC<CurrentUserProps> = (props) => {
  const { children } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<Partial<User>>(null);

  const updateAdminInformation = useCallback(
    async (updatedUser: Partial<User>) => {
      try {
        const { data: user } = await api.user.updatePersonalInformation(
          updatedUser,
        );
        setCurrentUser(user);
      } catch (e) {
        utils.toastError(e);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const getUser = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.user.getUserProfile();

      setCurrentUser(data);

      if (data?.language !== i18next.language) {
        updateAdminInformation({ language: i18next.language });
      }
    } catch (e) {
      utils.toastError(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }, [updateAdminInformation]);

  const uploadAdminFile = useCallback(async (files: File[]) => {
    OverlaySpinner.show('div');
    if (!files || files.length < 1) {
      return null;
    }
    const formData = new FormData();
    formData.append('file', files[0]);
    try {
      const { data } = await api.admins.uploadAdminFile(formData);

      setCurrentUser((oldUser) => ({
        ...oldUser,
        profileImage: data.profileImageUrl,
      }));

      return data;
    } catch (e) {
      utils.toastError(e);
    } finally {
      OverlaySpinner.hide('div');
    }
  }, []);

  const resetPassword = useCallback(
    async (values: ResetPasswordFormValues) => {
      setLoading(true);

      try {
        await api.admins.updateAdmin(
          { temporaryPassword: values.password },
          values?.userId,
        );

        credentialsService.consumeTmpBody();

        navigate('/');
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  const logout = useCallback(() => {
    // Remove auth data that we store in storage
    credentialsService.removeAuthBody();

    // Do a hard reload
    navigate('/auth');
  }, [navigate]);

  useEffect(() => {
    const customLogout = () => {
      refreshTokenService.reinit();
      logout();
      toast.error(t('Auth.sessionExpired'));
    };

    bus.addEventListener('LOGOUT_SIGNAL', customLogout);
    return () => {
      bus.removeEventListener('LOGOUT_SIGNAL', customLogout);
    };
  }, [t, logout]);

  useEffect(() => {
    if (!credentialsService.token && !credentialsService.tmpToken) {
      setLoading(false);
      return;
    }
    getUser();
  }, [getUser]);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        loading,
        setCurrentUser,
        logout,
        uploadAdminFile,
        updateAdminInformation,
        resetPassword,
        getUser,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUser;
