import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg width="26" height="26" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z"
      fill="#DEDEDE"
    />
    <rect x="6.5" y="17.5" width="11" height="1.5" rx="0.75" fill="#95989B" />
    <rect x="6.5" y="14.5" width="11" height="1.5" rx="0.75" fill="#95989B" />
    <rect x="6.5" y="11.5" width="7" height="1.5" rx="0.75" fill="#95989B" />
    <path d="M14 2V6C14 7.10457 14.8954 8 16 8H20L14 2Z" fill="#95989B" />
  </svg>
);
