import React from 'react';
import classNames from 'classnames';
import './ValidationError.styles.scss';

type ValidationErrorProps = {
  id?: string;
  className?: string;
  showError: boolean;
  error?: string | React.ReactNode;
};

const ValidationError: React.FC<ValidationErrorProps> = (props) => {
  const { id, className, showError, error } = props;

  const classes = classNames('hhsa-validation-error', className);

  return showError ? (
    <span id={id} className={classes}>
      {error}
    </span>
  ) : null;
};

export default ValidationError;
