import httpClient from '../httpClient';
import {
  Pagination,
  RequestParams,
  Searchable,
} from 'router/subrouters/Dashboard/pages/hooks/usePaginatedItems';
import { Activity, ActivityCount } from 'models/Activity';

class SearchActivity implements Searchable {
  keys() {
    return ['name'] as const;
  }
}

export type StaplesActivity = SearchActivity & Activity;

const getActivity = async (
  params: RequestParams<StaplesActivity>,
): Promise<Pagination<StaplesActivity>> => {
  return httpClient
    .get<Pagination<StaplesActivity>>('user-activities', { params })
    .then((response) => response.data);
};

const getActivityCount = (id: number) => {
  return httpClient
    .get<ActivityCount[]>(`user-activities/${id}/count`)
    .then((response) => response.data);
};

export default {
  getActivity,
  getActivityCount,
};
