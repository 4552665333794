import httpClient from 'api/httpClient';
import { Feature } from 'models/Feature';
import { AppVersion, Release } from 'types';
import env, { Env } from 'env';
import { Version } from 'models/Version';

const updateVersion = (version: AppVersion) =>
  httpClient.put<Feature[]>('/app-versions', version);

const getActiveVersion = () =>
  httpClient.get<Version>('/app-versions').then((response) => response.data);

const APP_CENTER_ENV: { [key in Env]: string } = {
  DEVELOPMENT: 'Handheld-Scanner-iOS',
  STAGING: 'Operations-Mobile-App',
  PRODUCTION: 'Staples-EASY',
};

const APP_CENTER_TOKEN: { [key in Env]: string } = {
  DEVELOPMENT: import.meta.env.VITE_APP_CENTER_TOKEN_DEVELOPMENT,
  STAGING: import.meta.env.VITE_APP_CENTER_TOKEN_STAGING,
  PRODUCTION: import.meta.env.VITE_APP_CENTER_TOKEN_PRODUCTION,
};

const getVersions = async (): Promise<Array<Release>> => {
  const response = await fetch(
    `https://api.appcenter.ms/v0.1/apps/Staples-Canada-ULC/${
      APP_CENTER_ENV[env.currentEnv]
    }/distribution_groups/Collaborators/releases`,
    {
      headers: {
        'X-API-Token': APP_CENTER_TOKEN[env.currentEnv],
      },
    },
  );
  return await response.json();
};

export default {
  updateVersion,
  getActiveVersion,
  getVersions,
};
