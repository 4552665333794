import api from 'api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FlagValUpdate } from 'api/features';

const useFeatureFlags = () => {
  const queryClient = useQueryClient();

  const { data: features, isLoading } = useQuery({
    queryKey: ['features'],
    queryFn: api.features.getStoreFeatures,
  });

  const { data: stores, isLoading: isLoadingStores } = useQuery({
    queryKey: ['storeNames'],
    queryFn: api.stores.getStoreNames,
  });

  const mutation = useMutation({
    mutationFn: (feature: Array<FlagValUpdate>) => {
      return api.features.updateStoreFeatures(feature);
    },
    onError: (err, newFeatures, context) => {
      // Restore state before optimistic update if error occurs
      queryClient.setQueryData(['features'], (context as any).previousFeatures);
    },
  });

  return {
    features,
    isLoading: isLoading || isLoadingStores,
    stores,
    mutation,
  } as const;
};

export default useFeatureFlags;
