import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const labels = useMemo(
    () => [
      { label: t('name'), key: 'name' },
      { label: t('storeNo'), key: 'storeNo' },
      { label: t('address'), key: 'address' },
      { label: t('city'), key: 'city' },
      { label: t('status'), key: 'status' },
    ],
    [t],
  );
  return labels;
};
