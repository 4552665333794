import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import axios from 'axios';

import './HttpError.styles.scss';

type HttpErrorProps = {
  error: unknown;
  className?: string;
  onTryAgain?: () => void;
};

const HttpError: React.FC<HttpErrorProps> = (props) => {
  const { className, error, onTryAgain } = props;

  const { t } = useTranslation();
  const classes = classNames('http-error', className);

  const errorMsg = useMemo(() => {
    if (axios.isAxiosError(error)) {
      const { status: statusCode } = error;
      if ([400, 401, 403, 404, 500].includes(+statusCode)) {
        return t(`httpErrors.${statusCode}`);
      }

      return t('httpErrors.default', { code: statusCode ?? error.message });
    }

    return t('httpErrors.default', { code: 'unknown' });
  }, [error, t]);

  return (
    <p className={classes}>
      <span>{errorMsg}</span>
      <Button styleType="secondary" onClick={onTryAgain}>
        {t('httpErrors.tryAgain')}
      </Button>
    </p>
  );
};

export default HttpError;
