import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import Header from 'components/Header';
import BackButton from 'components/BackButton';
import { useNavigate } from 'react-router-dom';
import { Button, Loader } from 'ncoded-component-library';
import Avatar from 'components/Avatar';

import './Profile.styles.scss';
import './Profile.styles.responsive.scss';

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser, logout } = useContext(CurrentUserContext);
  const navigate = useNavigate();

  const messages = useMemo(
    () => ({
      title: t('helloAdmin', { admin: currentUser?.name }),
      subtitle: t('yourProfile'),
      name: t('General.name'),
      associateId: t('General.associateId'),
      position: t('General.position'),
      storeNo: t('General.storeNo'),
      profileSettings: t('profileSettings'),
    }),
    [currentUser?.name, t],
  );

  if (!currentUser) {
    return <Loader color="#007cb9" inline />;
  }

  return (
    <>
      <Header
        rightComponent={
          <Button variant="outline" onClick={logout} className="header-logout">
            <span>{t('logout')}</span>
          </Button>
        }
      />
      <main className="hhsa-profile">
        <div className="back-title">
          <BackButton
            navigate={() => navigate('/')}
            className="hhsa-profile__back-button"
          />
          <h1>{messages.profileSettings}</h1>
        </div>

        <article className="hhsa-profile__info">
          <Avatar
            withInfo={false}
            className="hhsa-profile__avatar"
            img={currentUser?.profileImageUrl}
          />

          <dl>
            <div className="hhsa-profile__info__item">
              <dt>{t('name')}</dt>
              <dd>{currentUser?.name}</dd>
            </div>
            <div className="hhsa-profile__info__item">
              <dt>{t('General.associateId')}</dt>
              <dd>{currentUser?.associateId}</dd>
            </div>
            <div className="hhsa-profile__info__item">
              <dt>{t('position')}</dt>
              <dd>{currentUser?.position}</dd>
            </div>
          </dl>
        </article>
      </main>
    </>
  );
};

export default Profile;
