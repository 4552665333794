import React, { useMemo } from 'react';
import { Button } from 'ncoded-component-library';
import EditIcon from 'icons/Edit.icon';
import DeleteIcon from 'icons/Delete.icon';
import classNames from 'classnames';
import Avatar from 'components/Avatar';
import { useTranslation } from 'react-i18next';
import ProductImage from 'components/ProductImage';

import './Row.styles.scss';

type CellComponentNames = keyof typeof Row.CellComponents;

export type RowProps<T extends object> = {
  className?: string;
  el?: T;
  elementProps: string[];
  withActionButtons?: boolean;
  cellComponentMap?: Partial<Record<keyof T, CellComponentNames>>;
  onEdit?: (el: T) => void;
  onDelete?: (el: T) => Promise<void>;
  onRowClick?: (el: T) => void;
};

function Row<T extends object>(props: RowProps<T>) {
  const {
    className,
    el,
    elementProps,
    withActionButtons = false,
    onRowClick,
    onEdit,
    onDelete,
    cellComponentMap,
  } = props;

  return (
    <tr
      className={classNames('hhsa-row', { clickable: !!onRowClick }, className)}
      onClick={() => onRowClick?.(el)}
    >
      {elementProps.map((name) => (
        <Cell
          key={name}
          name={name}
          componentName={cellComponentMap?.[name as keyof T]}
        >
          {el[name as keyof T]}
        </Cell>
      ))}

      {withActionButtons && (
        <td className="hhsa-row__actions">
          <>
            <Button
              variant="icon"
              icon={<EditIcon />}
              className="hhsa-row__actions__edit"
              onClick={() => onEdit(el)}
            />

            {onDelete && (
              <Button
                variant="icon"
                icon={<DeleteIcon />}
                className="hhsa-row__actions__delete"
                onClick={() => onDelete(el)}
              />
            )}
          </>
        </td>
      )}
    </tr>
  );
}

const Trans: React.FC<{ value: string }> = ({ value }) => {
  const { t } = useTranslation();
  return <>{t(value)}</>;
};

type CellProps = {
  name: string;
  children: any;
  componentName?: CellComponentNames;
};

function Cell({ name, children, componentName }: CellProps) {
  const cellContent = useMemo(() => {
    if (componentName) {
      return React.createElement(Row.CellComponents[componentName], {
        value: children,
      });
    }

    if (/ima?ge?/i.test(name)) {
      return <Avatar withInfo={false} img={children} />;
    }

    return children;
  }, [componentName, children, name]);

  return <td className={name}>{cellContent || '-'}</td>;
}

type MapCompProps = { value: string };
Row.CellComponents = {
  Avatar: (p: MapCompProps) => <Avatar withInfo={false} img={p.value} />,
  Trans,
  ProductImage: (p: MapCompProps) => <ProductImage img={p.value} />,
} as const;

export default Row;
