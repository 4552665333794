import classNames from 'classnames';
import React from 'react';
import './Burger.component.scss';

type ButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  open: boolean;
};

const Burger: React.FC<ButtonProps> = ({ className, open, ...props }) => (
  <button
    className={classNames(
      'hhsa-burger',
      { 'hhsa-burger--open': open },
      className,
    )}
    {...props}
  >
    <span />
    <span />
    <span />
  </button>
);

export default Burger;
