import { AxiosError } from 'axios';
import i18next from 'i18next';

function isTouchDevice(): boolean {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function (query: string) {
    return window.matchMedia(query).matches;
  };

  if ('ontouchstart' in window || 'DocumentTouch' in window) {
    return true;
  }

  const query = ['(', prefixes.join('touch-enabled),('), 'ncoded', ')'].join(
    '',
  );
  return mq(query);
}

export const parseStringValue = (value: any) =>
  /^\d+$/.test(value)
    ? +value
    : /^(true|false)$/.test(value)
    ? JSON.parse(value)
    : value;

export function convertFormDataToJSONObject(formData: FormData) {
  const obj: any = {};
  formData.forEach((val, key) => {
    const isArray = key.includes('[]') || key.includes('files');

    if (isArray) {
      const newKey = key.split('[]')[0];
      if (!obj[newKey]) obj[newKey] = [];
      obj[newKey].push(parseStringValue(val));
    } else {
      obj[key] = parseStringValue(val);
    }
  });
  return obj;
}

export const convertObjToFormData = (
  obj: Record<string, any>,
  formData = new FormData(),
  path = '',
) => {
  if (obj === undefined) return;

  for (const prop in obj) {
    const newPath = path ? `${path}[${prop}]` : prop;
    if (typeof obj[prop] !== 'object') {
      if (obj[prop] instanceof File) {
        const file: File = obj[prop];
        formData.append(newPath, file, file.name);
      } else {
        formData.append(newPath, obj[prop]);
      }
    } else if (obj[prop] === null) {
      formData.append(newPath, obj[prop]);
    } else {
      convertObjToFormData(obj[prop], formData, newPath);
    }
  }

  return formData;
};

export function debounce(
  func: (...args: any[]) => void,
  wait: number,
  isImmediate = false,
) {
  let timeout: NodeJS.Timeout | number | null;
  return function (...args: any[]) {
    const later = () => {
      timeout = null;
      if (!isImmediate) {
        func(...args);
      }
    };
    const callNow = isImmediate && !timeout;
    if (timeout) clearTimeout(timeout as number);
    timeout = window.setTimeout(later, wait);
    if (callNow) {
      func(...args);
    }
  };
}

export const isAxiosError = (e: AxiosError | unknown): e is AxiosError =>
  'message' in (e as AxiosError) || 'response' in (e as AxiosError);

function toastError(e: AxiosError | unknown) {
  if (isAxiosError(e)) {
    console.error({ text: e.message || (e?.response.data as any)?.message });
    return;
  }

  console.error({ text: 'Error' });
}

const handleResponseError = (err: any) => {
  const { status, data: errData } = err.response || {};
  if (status >= 400 && status < 500) {
    return Array.isArray(errData.message)
      ? errData.message.join('')
      : errData.message || i18next.t('Errors.serverError');
  }
  if (status === 500) {
    return Array.isArray(errData.message)
      ? errData.message.join('')
      : errData.message || i18next.t('Errors.internalServerError');
  }
  return i18next.t('General.error');
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop(): any {}

const minutesInMiliseconds = (numberOfMinutes: number) =>
  numberOfMinutes * 60 * 1000;

const formatISODate = (dateString: string): string => {
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})/;
  const match = regex.exec(dateString);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]} ${match[4]}:${match[5]}`;
  }
  return '';
};

const handleQueryArrayParam = (currentValue: string, value: string) => {
  if (currentValue?.includes(value)) {
    const newValue = currentValue
      .replace(value, '')
      .replace(/^,\s*/, '')
      .replace(/,\s*$/, '')
      .replace(/,\s*,/, ',');

    return newValue;
  }
  const newValue = currentValue ? `${currentValue}, ${value}` : value;

  return newValue;
};

const convertCamelCaseToWords = (camelCase: string) =>
  camelCase
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
    .replace(/^./, (str) => str.toUpperCase());

export default {
  DEFAULT_PAGE: 1,
  DEFAULT_LIMIT: 20,
  minutesInMiliseconds,
  handleResponseError,
  noop,
  toastError,
  isTouchDevice,
  parseStringValue,
  convertObjToFormData,
  convertFormDataToJSONObject,
  debounce,
  formatISODate,
  handleQueryArrayParam,
  convertCamelCaseToWords,
};
