import React from 'react';
import { useTranslation } from 'react-i18next';
import './MoreInfoLink.styles.scss';

type MoreInfoLinkProps = {
  skuNo?: number;
};

const MoreInfoLink: React.FC<MoreInfoLinkProps> = (props) => {
  const { skuNo } = props;
  const { t } = useTranslation();

  return (
    <div className="hhsa-more-info-link">
      <span>{t('moreInformation')}</span>
      <a
        href={`https://www.staples.ca/search?query=${skuNo}`}
        target="_blank"
        rel="noreferrer"
      >
        {'Staples.ca.'}
      </a>
    </div>
  );
};

export default MoreInfoLink;
