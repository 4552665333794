import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => {
  const { ...restOfProps } = props;

  return (
    <svg
      fill="none"
      viewBox="0 0 36 36"
      width="36"
      height="36"
      {...restOfProps}
    >
      <path
        d="M2 18C2 9.1634 9.1634 2 18 2s16 7.1634 16 16-7.1634 16-16 16S2 26.8366 2 18Z"
        fill="#a2a4a6"
      />
      <path
        d="M25 18.0002H11M18.0002 25V11"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
