import { TimeFilters } from 'types';

const today = new Date();
today.setUTCHours(0, 0, 0, 0);

const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
lastWeek.setUTCHours(0, 0, 0, 0);

const lastMonth = new Date(today);
lastMonth.setUTCHours(0, 0, 0, 0);
lastMonth.setUTCMonth(lastMonth.getUTCMonth() - 1);

export const DATES: { [key in TimeFilters]: string } = {
  today: today.toISOString(),
  lastWeek: lastWeek.toISOString(),
  lastMonth: lastMonth.toISOString(),
  custom: '',
};
