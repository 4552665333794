import { useEffect, useState } from 'react';

export default function useScrollBottom(deps: Array<any> = []) {
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    setIsAtBottom(checkIsAtBottom());

    const handleScroll = () => {
      setIsAtBottom(checkIsAtBottom());
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return isAtBottom;
}

const checkIsAtBottom = () => {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop;

  const scrollHeight =
    document.documentElement.scrollHeight || document.body.scrollTop;

  const clientHeight =
    document.documentElement.clientHeight || document.body.clientHeight;

  return scrollHeight - (scrollTop + clientHeight) < 50;
};
