import React, { ReactNode, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Loader } from 'ncoded-component-library';
import { User } from 'models/User';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import ImageInput from 'components/ImageInput';
import avatar from '../../../public/assets/images/avatar.png';

import './Avatar.styles.scss';

type AvatarProps = {
  className?: string;
  user?: Partial<User>;
  img?: string;
  alt?: string;
  edit?: boolean;
  fallbackNode?: ReactNode;
  withInfo?: boolean;
  onClick?: () => void;
};

const Avatar: React.FC<AvatarProps> = (props) => {
  const {
    className,
    img,
    fallbackNode = <img src={avatar} alt="avatar" />,
    alt = 'avatar',
    edit = false,
    withInfo = true,
    user,
    onClick,
  } = props;

  const classes = classNames('hhsa-avatar', className);
  const { name, position } = user || {};
  const { uploadAdminFile } = useContext(CurrentUserContext);

  const [imgState, setImgState] = useState(img);

  useEffect(() => {
    setImgState(img);
  }, [img]);

  return (
    <div className={classes} onClick={onClick}>
      {withInfo &&
        (user ? (
          <p className="hhsa-avatar__avatar-info">
            <span>{`${name},`}</span>
            <span> {position}</span>
          </p>
        ) : (
          <Loader color="#007cb9" inline />
        ))}
      {imgState ? <img src={imgState} alt={alt} /> : <>{fallbackNode}</>}
      {edit && (
        <ImageInput
          onUploadImage={(image, file) => {
            setImgState(image);
            uploadAdminFile([file]);
          }}
        />
      )}
    </div>
  );
};

export default Avatar;
