import Axios, { AxiosRequestConfig } from 'axios';
import credentialsService from 'services/credentialsService';
import env from 'env';
import refreshTokenService from 'services/refreshTokenService';
import bus, { AuthEventTypes } from 'modules/bus';

const httpClient = Axios.create({
  baseURL: `${env.LOCAL_API_URL}/`,
});

const setToken = async (config: AxiosRequestConfig) => {
  const token = credentialsService.token || credentialsService.tmpToken;
  if (token && config.method !== 'OPTIONS') {
    if (!config.headers) config.headers = {};
    config.headers.Authorization = `Bearer ${token}`;
  }
};

const refresh = async (config: AxiosRequestConfig) => {
  await new Promise<void>((resolve, reject) => {
    function onRefreshTokenSuccess() {
      setToken(config);
      removeHandlers();
      resolve();
    }

    function onRefreshTokenFail() {
      reject(Error('Could not refresh token'));
      removeHandlers();
    }

    const removeSuccessListener = bus.addEventListener(
      AuthEventTypes.REFRESH_TOKEN_SUCCESS,
      onRefreshTokenSuccess,
    );
    const removeFailListener = bus.addEventListener(
      AuthEventTypes.REFRESH_TOKEN_FAIL,
      onRefreshTokenFail,
    );

    function removeHandlers() {
      removeSuccessListener();
      removeFailListener();
    }

    setTimeout(() => {
      reject(Error('Could not refresh token'));
      removeHandlers();
      refreshTokenService.refreshingAtm = false;
    }, refreshTokenService.checkRefreshingTreshold);
  });
};

httpClient.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    //If the header does not contain the token and the url not public, redirect to login
    config.baseURL = env.LOCAL_API_URL;

    await setToken(config);
    if (refreshTokenService.refreshingAtm) {
      await refresh(config);
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

httpClient.interceptors.response.use(null, async (error) => {
  const { response, config } = error;

  if (response && [401].includes(response.status)) {
    if (refreshTokenService.triedRefresh) {
      return Promise.reject(error);
    }

    if (refreshTokenService.refreshingAtm) {
      await refresh(config);
      return httpClient(config);
    }

    try {
      await refreshTokenService.refreshToken();
      return httpClient(config);
    } catch (err) {
      bus.broadcastEvent('LOGOUT_SIGNAL');
      error.preventDefault = true;
    }
  }
  return Promise.reject(error);
});

export default httpClient;
