import auth from './auth';
import user from './user';
import associates from './associates';
import stores from './stores';
import admins from './admins';
import features from './features';
import products from './products';
import analytics from './analytics';
import version from './version';

export default {
  auth,
  user,
  associates,
  admins,
  stores,
  features,
  products,
  analytics,
  version,
};
