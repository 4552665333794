import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import formValidators from 'utils/formValidators';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { Select } from 'ncoded-component-library';
import ValidationError from 'components/ValidationError';
import type {
  SelectProps,
  OptionValue,
} from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import './DropdownSelect.styles.scss';

type SimpleSelectValue = string | number;
type BaseSelectFieldProps = Omit<
  SelectProps<SimpleSelectValue>,
  'value' | 'onChange'
> & {
  name: string;
  label?: React.ReactNode;
  keepOnlyValueInField?: boolean;
  onChange?: (value: string | OptionValue<string>) => void;
};

type SelectFieldProps = BaseSelectFieldProps &
  UseFieldConfig<OptionValue<SimpleSelectValue> | SimpleSelectValue>;

type OnChangeKeepValue = (value: SimpleSelectValue) => void;
type SelectFieldComponentProps = BaseSelectFieldProps &
  FieldRenderProps<
    OptionValue<SimpleSelectValue> | SimpleSelectValue,
    HTMLElement
  >;

const SelectFieldComponent: React.FC<SelectFieldComponentProps> = (props) => {
  const {
    className,
    input,
    meta,
    label,
    name,
    searchInputProps = {},
    keepOnlyValueInField = true,
    onChange,
    searchable,
    ...restOfProps
  } = props;

  const classes = classNames('hhsa-dropdown-select', className);

  const { error, hasError } = useMemo(
    () => formValidators.getErrorFromMeta(meta),
    [meta],
  );

  const { onChange: inputOnChange, onFocus, value, ...restOfInput } = input;

  const inputProps = useMemo(
    () => ({ ...searchInputProps, ...restOfInput }),
    [restOfInput, searchInputProps],
  );

  const handleOnChange = useCallback(
    (option: OptionValue<string | number>) => {
      const val = keepOnlyValueInField ? option.value : option.value;

      if (keepOnlyValueInField && onChange)
        (onChange as OnChangeKeepValue)(option.value);

      inputOnChange(val);
    },
    [inputOnChange, keepOnlyValueInField, onChange],
  );

  const select = useMemo(() => {
    return (
      <Select
        {...restOfProps}
        multiple={false}
        onChange={handleOnChange}
        value={value}
        searchInputProps={inputProps}
        hasError={hasError}
        searchable={searchable}
      />
    );
  }, [handleOnChange, hasError, inputProps, restOfProps, searchable, value]);

  return (
    <div className={classes}>
      {label && <label htmlFor={name}>{label}</label>}
      {select}
      <ValidationError error={error} showError={hasError} />
    </div>
  );
};

const SelectField: React.FC<SelectFieldProps> = (props) => (
  <Field component={SelectFieldComponent} {...props} type="select" />
);

export default SelectField;
