import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import utils, { isAxiosError } from 'utils';
import validations from 'validations';
import { SortDirection, TimeFilters } from 'types';
import { toast } from 'react-toastify';

export type BaseParams = {
  page?: string;
  limit: string;
  storeNo?: string;
};

export type AdditionalParams = {
  startDate?: string;
  endDate?: string;
  types?: string[];
  position?: string;
  userId?: string;
  storeNo?: string;
  sortBy?: string;
  sortDir?: SortDirection;
  timeFilter?: TimeFilters;
};

type ItemId = string | number;
type WithId<T> = T & { id: ItemId };

export type Item = WithId<Searchable>;

export type Pagination<T> = {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  items: T[];
};

export interface Searchable {
  keys(): Readonly<string[]>;
}

export type RequestParams<T extends Searchable> = BaseParams &
  Partial<Record<ReturnType<T['keys']>[number], string>>;

type PaginatedArgs<T extends Searchable> = {
  queryKey: string;
  makeRequest: (params: RequestParams<T>) => Promise<Pagination<T>>;
  params: RequestParams<T>;
  additionalParams?: AdditionalParams;
  enabled?: boolean;
  searchParam?: T extends Searchable ? ReturnType<T['keys']>[number] : string;
  staleTime?: number;
  validateLimit?: (limit: string) => string;
  validatePage?: (page: string) => string;
  refetchOnMount?: boolean | 'always';
};

const defaultStaleTime = utils.minutesInMiliseconds(1);

function usePaginatedItems<T extends Item>(pagination: PaginatedArgs<T>) {
  const {
    params,
    additionalParams = {},
    makeRequest,
    queryKey,
    searchParam,
    enabled = true,
    staleTime = defaultStaleTime,
    validateLimit = validations.filterValidations.defaultValidateLimit,
    validatePage = validations.filterValidations.defaultValidatePage,
    refetchOnMount,
  } = pagination;

  const [isRefreshing, setIsRefreshing] = useState(false);
  const { page, limit, ...restParams } = params;
  const { sortDir, sortBy } = additionalParams || {};

  const lastSearchParamRef = useRef(params[searchParam]);

  const otherKeys = Object.values(restParams);

  const validatedPage = validatePage(page);
  const validatedLimit = validateLimit(limit);

  const queryKeys = useMemo(
    () =>
      [
        queryKey,
        validatedPage,
        validatedLimit,
        searchParam,
        sortBy,
        sortDir,
        ...Object.values(additionalParams),
        ...otherKeys,
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      queryKey,
      validatedLimit,
      validatedPage,
      sortBy,
      sortDir,
      searchParam,
      additionalParams,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ...otherKeys,
    ],
  );

  const { isLoading, isError, error, data, isFetching, isRefetching, refetch } =
    useQuery({
      queryKey: queryKeys,
      enabled,
      staleTime,
      cacheTime: staleTime,
      refetchOnMount,
      queryFn: () =>
        makeRequest({
          ...additionalParams,
          ...restParams,
          page: validatedPage,
          limit: validatedLimit,
        } as RequestParams<T>),
      getNextPageParam: (lastPage) => {
        if (searchParam && params[searchParam] !== lastSearchParamRef.current) {
          lastSearchParamRef.current = params[searchParam];
          return 1;
        }
        if (lastPage.currentPage === lastPage.totalPages) return undefined;
        return lastPage.currentPage + 1;
      },
      onError: (err) => {
        if (isAxiosError(err)) {
          toast.error((err?.response.data as any)?.message || err.message);

          return;
        }

        toast.error('Error');
      },
    });

  const totalItems = useMemo(() => data?.totalItems || 0, [data]);

  const totalPages = Math.ceil(totalItems / (+validatedLimit || 10));

  const onRefresh = useCallback(() => {
    refetch({ refetchPage: (_, index) => index === 0 });
    setIsRefreshing(true);
  }, [refetch]);

  useEffect(() => {
    if (!isFetching) {
      setIsRefreshing((old) => old && false);
    }
  }, [isFetching]);

  return {
    isLoading,
    isError,
    error,
    items: data?.items,
    isFetching,
    isRefetching,
    isRefreshing,
    onRefresh,
    refetch,
    totalItems,
    totalPages,
  };
}

export default usePaginatedItems;
