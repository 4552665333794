import { FieldValidator } from 'final-form';
import i18next, { TFunction } from 'i18next';
import { FieldMetaState } from 'react-final-form';
import { FlagValue } from 'router/subrouters/Dashboard/pages/FeatureFlags/components/Flag/Flag.component';

const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^\da-zA-Z]).{8,}$/;
const EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const ASSOCIATE_ID = /^[1-9]\d*$/;

const VALID = undefined as undefined;

export const MAX_ASSOCIATE_ID_CHARS = 15;
export const MAX_STORE_NO_CHARS = 3;

const getErrorFromMeta = <T>(
  meta: FieldMetaState<T>,
): { hasError: boolean; error: string } => {
  const { invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!(touched && invalid && error);

  return { hasError, error };
};

const composeValidators =
  (...validators: any[]) =>
  (value: string) => {
    for (const validator of validators) {
      const error = validator(value);
      if (error) {
        return error;
      }
    }

    return VALID;
  };

const isNonEmptyString = (val: any) => {
  return typeof val === 'string' && val.trim().length > 0;
};
const isEmptyValue = (val: any) => typeof val === 'undefined' || val === null;

const required =
  <T>(message: string): FieldValidator<T> =>
  (value: T) => {
    if (isEmptyValue(value)) {
      // undefined or null values are invalid
      return message;
    }

    if (typeof value === 'string') {
      // string must be nonempty when trimmed
      return isNonEmptyString(value) ? VALID : message;
    }

    return VALID;
  };

const minChars = (chars: number, errorMessage: string) => (value: string) =>
  value && value.length >= chars ? VALID : errorMessage;

const maxChars =
  <T>(message: string, maxLength: number): FieldValidator<T> =>
  (value: any) => {
    if (typeof value === 'number') {
      value = String(value);
    }

    const hasLength = value && typeof value.length === 'number';

    return hasLength && value.length <= maxLength ? VALID : message;
  };

const passwordValidation = (errorMessage: string) => (value: string) =>
  PASSWORD_REGEX.test(value) ? VALID : errorMessage;

const emailFormatValid =
  (message: string): FieldValidator<string> =>
  (value: string) => {
    return value && EMAIL.test(value) ? VALID : message;
  };

const getEmailValidators = (
  t: TFunction,
  isRequired = true,
  requiredMsg = 'General.emailRequired',
  invalidFormatMsg = 'General.emailInvalid',
): FieldValidator<string> | ((value: string) => FieldValidator<string>) => {
  const validFormat = emailFormatValid(t(invalidFormatMsg));

  if (isRequired) {
    return composeValidators(required(t(requiredMsg)), validFormat);
  }

  return validFormat;
};

const flagValidator: FieldValidator<FlagValue> = (value) => {
  if (!value?.isEnabled) return VALID;

  if (value.type === 'all') return VALID;

  if (value.type === 'exclude' && !value.excludeStoreNo.length) {
    return i18next.t('FeatureFlags.Errors.addStoreToFilter');
  }

  if (value.type === 'include' && !value.includeStoreNo.length) {
    return i18next.t('FeatureFlags.Errors.addStoreToFilter');
  }

  return VALID;
};

const noSpaces = (message: string) => (value: string) => {
  return /\s/.test(value) ? message : VALID;
};

const getPasswordValidators = (
  t: TFunction,
  requiredMsg = 'General.passwordRequired',
  minLengtMsg = 'General.passwordMinLength',
  passwordRequirements = 'General.passwordRequirements',
) =>
  composeValidators(
    required(t(requiredMsg)),
    minChars(
      PASSWORD_MIN_LENGTH,
      t(minLengtMsg, { minLength: PASSWORD_MIN_LENGTH }),
    ),
    passwordValidation(t(passwordRequirements)),
  );

const associateIDValidation = (errorMessage: string) => (value: number) => {
  return ASSOCIATE_ID.test(`${value}`) ? VALID : errorMessage;
};

export default {
  composeValidators,
  flagValidator,
  required,
  getEmailValidators,
  minChars,
  maxChars,
  passwordValidation,
  getErrorFromMeta,
  getPasswordValidators,
  noSpaces,
  associateIDValidation,
};
