import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

type HeightAnimationProps = {
  element: HTMLElement | null | undefined;
  duration?: number;
  defaultOpen?: boolean;
  onAnimationEnd?: (isOpen: boolean) => void;
};

const emptyStyles = {} as const;

const defaultStyles = {
  height: 0,
} as const;

export default function useHeightAnimation(props: HeightAnimationProps) {
  const {
    element,
    onAnimationEnd,
    defaultOpen = false,
    duration = 300,
  } = props;

  const onAnimationEndRef = useRef(onAnimationEnd);

  const [open, setOpen] = useState(defaultOpen);
  const animationStartRef = useRef<number>();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const activeAnimationCycle = useRef(0);
  const openRef = useRef(open);

  const elStyles =
    activeAnimationCycle.current && !defaultOpen ? emptyStyles : defaultStyles;

  const classes = useMemo(
    () => classNames({ transitioning: isTransitioning }),
    [isTransitioning],
  );

  const animate = useCallback(
    (timestamp: number, cycleId: number, first: number, last: number) => {
      if (!element || cycleId !== activeAnimationCycle.current) return;

      const elapsedTime = timestamp - (animationStartRef.current || timestamp);

      const { current: open } = openRef;

      const currentHeight = parseInt(element.style.height);

      const done = open
        ? currentHeight >= element.scrollHeight
        : currentHeight === 0;

      if (done) {
        if (open) {
          element.removeAttribute('style');
        } else {
          element.style.height = '0px';
          element.style.setProperty('--value', '0%');
        }

        onAnimationEndRef.current?.(open);

        setIsTransitioning(false);

        return;
      } else {
        const ratio = elapsedTime / duration;
        const height = Math.max(
          0,
          Math.min(element.scrollHeight, first + ratio * (last - first)),
        );
        element.style.height = `${height}px`;
        element.style.setProperty(
          '--value',
          `${Math.min(100, (100 * height) / element.scrollHeight)}%`,
        );
      }

      requestAnimationFrame((timestamp) =>
        animate(timestamp, cycleId, first, last),
      );
    },
    [duration, element],
  );

  useEffect(() => {
    if (!element) return;

    let first: number;
    let last: number;

    if (open) {
      // current element height
      // should be 0 or in between for the closed state
      first = element.clientHeight;
      last = element.scrollHeight;
    } else {
      first = element.clientHeight;
      last = 0;
    }

    if (first === last) return;

    setIsTransitioning(true);
    requestAnimationFrame((timestamp) => {
      animationStartRef.current = timestamp;
      animate(timestamp, ++activeAnimationCycle.current, first, last);
    });
  }, [animate, element, open]);

  useEffect(() => {
    openRef.current = open;
  }, [open]);

  return { open, setOpen, classes, elStyles } as const;
}
