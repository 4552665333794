import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import NoSearchMatchIcon from 'icons/NoSearchMatch.icon';

import './NoSearchMatch.styles.scss';

export type NoSearchMatchProps = {
  className?: string;
  promptMessage?: string;
};

const NoSearchMatch: React.FC<NoSearchMatchProps> = (props) => {
  const { t } = useTranslation();

  const { className, promptMessage = t('changeSearch') } = props;

  const classes = classNames('hhsa-no-search-match', className);

  return (
    <div className={classes}>
      <p>{t('noMatches')}</p>
      <p>{promptMessage}</p>
      <NoSearchMatchIcon />
    </div>
  );
};

export default NoSearchMatch;
