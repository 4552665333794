import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  Button,
  Modal,
  RadioGroup,
  Select,
  Toggle,
} from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import useHeightAnimation from 'hooks/useHeightAnimation';
import useCallbackRef from 'hooks/useCallbackRef';
import type { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import CircleXmarkIcon from 'icons/CircleXmark.icon';

import './Flag.styles.scss';

const flagSelectionTypes = ['include', 'exclude', 'all'] as const;

type FlagSelectionType = (typeof flagSelectionTypes)[number];

export type FlagValue = {
  isEnabled: boolean;
  includeStoreNo: number[];
  excludeStoreNo: number[];
  type: FlagSelectionType;
};

export type FlagProps = {
  className?: string;
  flagName?: string;
  options: OptionValue<number>[];
  value: FlagValue;
  onChange: (value: Partial<FlagValue>) => void;
};

const SLICE_SIZE = 15;

const Flag: React.FC<FlagProps> = (props) => {
  const {
    className,
    flagName,
    options: storeOptions,
    value,
    value: {
      isEnabled,
      excludeStoreNo,
      includeStoreNo,
      type: storeSelectionType,
    },
    onChange,
  } = props;

  const [element, elementRef] = useCallbackRef<HTMLDivElement>();
  const headerRef = useRef<HTMLElement>();
  const [showAllModalOpen, setShowAllModalOpen] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const { t } = useTranslation();

  const { classes: animationClasses, setOpen: setAnimationOpen } =
    useHeightAnimation({
      element,
      defaultOpen: isEnabled,
      onAnimationEnd: (isOpen) => isOpen && headerRef.current.scrollIntoView(),
    });

  const options = useMemo(() => {
    return [
      {
        label: t('FeatureFlags.SelectionTypes.include'),
        value: 'include',
      },
      {
        label: t('FeatureFlags.SelectionTypes.exclude'),
        value: 'exclude',
      },
      { label: t('FeatureFlags.SelectionTypes.all'), value: 'all' },
    ];
  }, [t]);

  const selectedStores = useMemo(() => {
    switch (storeSelectionType) {
      case 'exclude':
        return storeOptions.filter((s) => excludeStoreNo.includes(s.value));

      case 'include':
        return storeOptions.filter((s) => includeStoreNo.includes(s.value));
      default:
        return null;
    }
  }, [excludeStoreNo, includeStoreNo, storeOptions, storeSelectionType]);

  const slicedSelectedStores = useMemo(
    () => selectedStores?.slice(0, SLICE_SIZE),
    [selectedStores],
  );

  useEffect(() => {
    setAnimationOpen(isEnabled);
  }, [isEnabled, setAnimationOpen]);

  const selectVals =
    storeSelectionType === 'exclude' ? excludeStoreNo : includeStoreNo;

  const selectKey =
    storeSelectionType === 'exclude' ? 'excludeStoreNo' : 'includeStoreNo';

  const storesAreSliced = selectedStores?.length > slicedSelectedStores?.length;

  useEffect(() => {
    if (!showAllModalOpen) return;

    if (!selectedStores.length) setShowAllModalOpen(false);
  }, [selectedStores?.length, showAllModalOpen]);

  return (
    <>
      <section className={classNames('hhsa-flag', className, animationClasses)}>
        <header ref={headerRef}>
          <span
            className={classNames('hhsa-flag__title', { disabled: !isEnabled })}
          >
            {t(`${flagName}`)}
          </span>
          <div className="hhsa-flag__toggle">
            <span>
              {isEnabled
                ? t('FeatureFlags.enabled')
                : t('FeatureFlags.disabled')}
            </span>
            <Toggle
              checked={isEnabled}
              onChange={(ev) =>
                onChange({ ...value, isEnabled: ev.target.checked })
              }
              className="hhsa-flag__toggle"
            />
          </div>
        </header>
        <main ref={elementRef}>
          <div>
            <RadioGroup
              direction="row"
              name="storeSelectionType"
              value={storeSelectionType}
              onChange={(ev) =>
                onChange({
                  ...value,
                  type: ev.target.value as FlagSelectionType,
                })
              }
              options={options}
            />
            <div>
              <>
                {selectedStores?.length ? (
                  <>
                    <p>
                      {storeSelectionType === 'exclude'
                        ? t('FeatureFlags.SelectionExplanations.exclude')
                        : t('FeatureFlags.SelectionExplanations.include')}
                    </p>
                    <div className="selected-stores">
                      {slicedSelectedStores.map(({ value: storeNo, label }) => (
                        <span key={storeNo} className="selected-store">
                          <span>{label as string}</span>
                          <button
                            type="button"
                            className="x-icon"
                            onClick={() =>
                              onChange({
                                ...value,
                                [selectKey]: value[selectKey].filter(
                                  (sn) => storeNo !== sn,
                                ),
                              })
                            }
                          >
                            <CircleXmarkIcon />
                          </button>
                        </span>
                      ))}

                      {storesAreSliced && (
                        <Button
                          variant="link"
                          onClick={() => setShowAllModalOpen(true)}
                        >
                          {t('FeatureFlags.showAll', {
                            count: selectedStores.length,
                          })}
                        </Button>
                      )}
                    </div>
                  </>
                ) : (
                  storeSelectionType !== 'all' && (
                    <p>{t('FeatureFlags.selectStores')}</p>
                  )
                )}

                {storeSelectionType !== 'all' && (
                  <Select
                    key={storeSelectionType}
                    value={selectVals}
                    innerLabel={t('FeatureFlags.search')}
                    multiple
                    searchable
                    renderAsPortal
                    portalClassName="store-multi-select-portal"
                    onSearchChange={(ev) => setSearchValue(ev.target.value)}
                    noOptionsFoundContent={t('no') + searchValue}
                    selectedOptionsContent={(vals) => (
                      <>
                        <span>
                          {t('FeatureFlags.totalItems')} <b>{vals.length}</b>{' '}
                        </span>
                        <button
                          onClick={(ev) => {
                            ev.stopPropagation();
                            onChange({ ...value, [selectKey]: [] });
                          }}
                        >
                          {t('FeatureFlags.clearAll')}
                        </button>
                      </>
                    )}
                    options={storeOptions}
                    onChange={(vals) =>
                      onChange({ ...value, [selectKey]: vals })
                    }
                  />
                )}
              </>
            </div>
          </div>
        </main>
      </section>
      <Modal
        open={showAllModalOpen}
        focusableElements="button"
        renderAsPortal
        controlledByParent
        overlayCloses
        onOverlayClose={() => setShowAllModalOpen(false)}
        footer={
          <div className="flex-row-sb-center">
            <p>
              {t('FeatureFlags.totalItems')} {selectedStores?.length}
            </p>
            <Button onClick={() => setShowAllModalOpen(false)}>
              {t('General.close')}
            </Button>
          </div>
        }
        className="store-selection-modal"
        title={
          <div>
            <p>{flagName}</p>
            <p>
              {t('FeatureFlags.showingStoresFrom', {
                isEnabled:
                  storeSelectionType === 'include'
                    ? t('FeatureFlags.enabled')
                    : t('FeatureFlags.disabled'),
              })}
            </p>
          </div>
        }
      >
        <ul>
          {selectedStores?.map((store) => (
            <li key={store.value}>
              <p>{store.label as string}</p>
              <Button
                variant="link"
                onClick={() =>
                  onChange({
                    ...value,
                    [selectKey]: value[selectKey].filter(
                      (sn) => store.value !== sn,
                    ),
                  })
                }
              >
                {t('remove')}
                <CircleXmarkIcon />
              </Button>
            </li>
          ))}
        </ul>
      </Modal>
    </>
  );
};

export default Flag;
