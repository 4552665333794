import React, { useId } from 'react';
import classNames from 'classnames';
import { ExtractPropTypes } from 'types';
import { Input as InputNcLib } from 'ncoded-component-library';
import ValidationError from 'components/ValidationError';

import './Input.styles.scss';

export type DefaultInputProps = ExtractPropTypes<typeof InputNcLib>;

export type InputProps = {
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  label?: React.ReactNode;
  name: string;
  error?: string;
  inputRef?: React.Ref<HTMLInputElement>;
} & DefaultInputProps;

const Input: React.FC<InputProps> = (props) => {
  const {
    className,
    labelClassName,
    inputClassName,
    label,
    name,
    hasError,
    error,
    type,
    onKeyDown,
    inputRef,
    ...rest
  } = props;

  const id = useId();

  const classes = classNames('hhsa-input', className);

  const labelClasses = classNames('hhsa-input__label', labelClassName);

  const inputClasses = classNames(
    'hhsa-input__input',

    inputClassName,
  );

  const validationId = `${id}-error`;

  return (
    <div className={classes}>
      {label && (
        <label htmlFor={id} className={labelClasses}>
          <span>{label}</span>
        </label>
      )}

      <InputNcLib
        {...rest}
        id={id}
        type={type}
        hasError={hasError}
        name={name}
        className={inputClasses}
        ref={inputRef}
        {...(hasError && {
          'aria-invalid': true,
          'aria-describedby': validationId,
        })}
      />

      <ValidationError id={id} showError={hasError} error={error} />
    </div>
  );
};

export default Input;
