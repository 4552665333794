import React, { useContext } from 'react';
import Header from 'components/Header';
import SideBar from 'components/SideBar';
import { Outlet, useNavigate } from 'react-router-dom';
import Avatar from 'components/Avatar';
import { useTranslation } from 'react-i18next';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { Button } from 'ncoded-component-library';

import './Dashboard.styles.scss';
import './Dashboard.styles.responsive.scss';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const { logout, currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();

  return (
    <>
      <Header
        rightComponent={
          <>
            <Avatar
              onClick={() => navigate('/profile')}
              user={currentUser}
              img={currentUser?.profileImageUrl}
            />
            <Button
              variant="outline"
              onClick={logout}
              className="header-logout"
            >
              <span>{t('logout')}</span>
            </Button>
          </>
        }
      />
      <SideBar />
      <main className="hhsa-dashboard__main">
        <Outlet />
      </main>
    </>
  );
};

export default Dashboard;
