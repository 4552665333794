import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from 'ncoded-component-library';
import useFeatureFlags from './hooks/useFeatureFlags';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import { Form, Field } from 'react-final-form';
import FieldFlag from './components/FieldFlag';
import { StaplesStore } from 'api/stores';
import formValidators from 'utils/formValidators';
import { FlagValue } from './components/Flag/Flag.component';

import './FeatureFlags.styles.scss';
import { toast } from 'react-toastify';

const FeatureFlags: React.FC = () => {
  const { t } = useTranslation();
  const { features, isLoading, stores, mutation } = useFeatureFlags();

  const formRef = useRef<HTMLFormElement>();

  const storeOptions: OptionValue<number>[] = useMemo(() => {
    if (!stores?.length) return [];
    return (stores as StaplesStore[]).map((s) => ({
      value: s.storeNo,
      label: s.name,
    }));
  }, [stores]);

  const initialValues = useMemo(() => {
    return features?.reduce(
      (acc, { featureName, value, storeNumbers, isStoreIncluded }) => {
        const excludeStoreNo =
          isStoreIncluded || value !== null ? [] : storeNumbers;

        const includeStoreNo =
          isStoreIncluded && value === null ? storeNumbers : [];

        return {
          ...acc,
          [featureName]: {
            isEnabled: value ?? true,
            excludeStoreNo,
            includeStoreNo,
            type: excludeStoreNo.length
              ? 'exclude'
              : includeStoreNo.length
              ? 'include'
              : 'all',
          } as const,
        };
      },
      {} as Record<string, FlagValue>,
    );
  }, [features]);

  const onSubmit = useCallback(
    async (formValues: Record<string, FlagValue>, formApi: any) => {
      const flagUpdArr = Object.entries(formValues).map(
        ([flagName, { type, excludeStoreNo, includeStoreNo, isEnabled }]) => ({
          featureName: flagName,
          isEnabled,
          excludeStoreNo: type === 'exclude' && isEnabled ? excludeStoreNo : [],
          includeStoreNo: type === 'include' && isEnabled ? includeStoreNo : [],
        }),
      );

      try {
        await mutation.mutateAsync(flagUpdArr);
        formApi.reset(formValues);
        toast.success(t('FeatureFlags.successfullyUpdated'));
      } catch (er) {
        formApi.reset(initialValues);
        toast.error(t('FeatureFlags.failedUpdating'));
      }
    },
    [t, mutation, initialValues],
  );

  if (isLoading) {
    return <Loader color="#007cb9" inline />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(formRenderProps) => {
        const { handleSubmit, dirty } = formRenderProps;

        const submitDisabled = !dirty || mutation.isLoading;

        return (
          <form
            ref={formRef}
            className="hhsa-feature-flags"
            onSubmit={(ev) => {
              handleSubmit(ev);

              // check for errors
              const errorEl = formRef.current.querySelector('.field-error');
              if (errorEl) errorEl.scrollIntoView();
            }}
          >
            <h1>{t('featureFlags')}</h1>
            <ul className="hhsa-feature-flags__list">
              {features?.map(({ featureName }) => (
                <li key={featureName}>
                  <Field
                    component={FieldFlag}
                    name={featureName}
                    flagName={featureName}
                    options={storeOptions}
                    validate={formValidators.flagValidator}
                  />
                </li>
              ))}
            </ul>
            <div className="hhsa-feature-flags__form__footer">
              <Button disabled={submitDisabled} type="submit">
                {t('save')}
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default FeatureFlags;
