import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import aisleOptixLogo from '../../../../../../public/aisle-optix-logo.png';
import { useNavigate } from 'react-router-dom';
import { Loader, Select } from 'ncoded-component-library';
import env, { Env } from 'env';
import { environmentOptions } from 'types';
import { useTranslation } from 'react-i18next';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import useQueryParams from 'hooks/useQueryParams';
import credentialsService from 'services/credentialsService';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import utils from 'utils';
import LanguageDropdownSelect from 'components/LanguageDropdownSelect';
import { toast } from 'react-toastify';
import LoginForm from './components/LoginForm';
import { useAuth } from '../../Auth.router';
import { LoginReqBody } from 'models/AuthBody';

import './Login.styles.scss';
import './Login.styles.responsive.scss';

type LoginProps = {
  className?: string;
};

const Login: React.FC<LoginProps> = (props) => {
  const { className } = props;

  const [envValue, setEnvValue] = useState(env.currentEnv);
  const [fetchUserError, setFetchUserError] = useState(false);

  const { getUser, loading: loadingUser } = useContext(CurrentUserContext);
  const { login } = useAuth();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const classes = classNames('hhsa-login', className);

  const {
    params: { token, refreshToken, statusCode, message },
  } = useQueryParams<{
    token: string;
    refreshToken: string;
    statusCode: string;
    message: string;
  }>();

  const environment = useMemo(
    () =>
      Object.values(environmentOptions).map(({ value, label }) => ({
        label: t(`${label}`),
        value,
      })),
    [t],
  );

  const didToastSSOError = useRef(false);

  const onSubmit = useCallback(
    async (values: LoginReqBody) => {
      try {
        await login(values);
      } catch (err) {
        // Error handled in parent
      }
    },
    [login],
  );

  useEffect(() => {
    if (!!statusCode && !!message && !didToastSSOError.current) {
      toast.error(`Error ${statusCode}: ${message}`);

      didToastSSOError.current = true;
    }
  }, [statusCode, message]);

  useEffect(() => {
    if (!env.accessDifferentEnvs) return;

    env.currentEnv = envValue;
  }, [envValue]);

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        credentialsService.saveAuthBody({ token, refreshToken });

        await getUser();

        navigate('/');
      } catch (error) {
        setFetchUserError(true);
        credentialsService.removeAuthBody();
        utils.toastError(error);
      }
    };

    if (token && refreshToken && !loadingUser && !fetchUserError) {
      getCurrentUser();
    }
  }, [getUser, loadingUser, navigate, refreshToken, token, fetchUserError]);

  return (
    <div className={classes}>
      <img className="hhsa-login__logo" src={aisleOptixLogo} alt="logo" />

      {env.accessDifferentEnvs && (
        <div className="hhsa-login__environment-select">
          <LanguageDropdownSelect isClientOnly />
          <Select
            className="hhsa-dropdown-select"
            name="env"
            options={environment}
            value={envValue}
            onChange={(option: OptionValue<Env>) => setEnvValue(option.value)}
            innerLabel={t('chooseEnv')}
          />
        </div>
      )}

      <div className="hhsa-login__message">{t('loginMessage')}</div>

      {loadingUser ? (
        <Loader color="#007cb9" inline />
      ) : (
        <LoginForm inProgress={false} onSubmit={onSubmit} />
      )}
    </div>
  );
};

export default Login;
