import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import './ManageUsersForm.styles.scss';
import { Form, FormProps } from 'react-final-form';
import { SubmissionErrors } from 'final-form';
import InputField from 'components/InputField';
import { useTranslation } from 'react-i18next';
import PasswordField from 'components/PasswordField';
import { Button, Loader } from 'ncoded-component-library';
import useAssociate from './hooks/useAssociate';
import formValidators, {
  MAX_ASSOCIATE_ID_CHARS,
  MAX_STORE_NO_CHARS,
} from 'utils/formValidators';
import { ManageUsersFormBody } from 'models/Associates';
import { User } from 'models/User';
import SelectField from 'components/SelectField';
import { inventoryScreenType, positionOptions } from 'types';

const { required } = formValidators;

type ManageUsersFormProps = {
  className?: string;
  onSubmit: (
    values: ManageUsersFormBody,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  handleCancel?: () => void;
  associateId?: number;
  inProgress: boolean;
} & FormProps<ManageUsersFormBody>;

const ManageUsersForm: React.FC<ManageUsersFormProps> = (props) => {
  const { className, associateId, inProgress, handleCancel, onSubmit } = props;
  const { associate, loading } = useAssociate(associateId);
  const [changePass, setChangePass] = useState(false);

  const classes = classNames('hhsa-manage-users-form', className);
  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      associateName: t('General.associateName'),
      associateId: t('General.associateId'),
      position: t('position'),
      storeNumber: t('storeNumber'),
      password: t('General.password'),
      confirmNewPassword: t('General.confirmNewPassword'),
      cancel: t('General.cancel'),
      addAssociate: t('addAssociate'),
      updateAssosiate: t('updateAssociate'),
      passwordMismatch: t('General.passwordMismatch'),
      id: t('id'),
      moveInventoryStyle: t('moveInventoryStyle'),
      changePass: t('General.changePassword'),
    }),
    [t],
  );

  const passwordValidators = useMemo(
    () => formValidators.getPasswordValidators(t),
    [t],
  );

  const passwordMatchValidator = useCallback(
    (values: ManageUsersFormBody) => {
      const errors: Partial<ManageUsersFormBody> = {};

      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = messages.passwordMismatch;
      }

      return errors;
    },
    [messages.passwordMismatch],
  );
  const inventoryStyle = useMemo(
    () =>
      Object.values(inventoryScreenType).map(({ label, value }) => ({
        label: t(`${label}`),
        value,
      })),
    [t],
  );
  const positions = useMemo(
    () =>
      Object.values(positionOptions).map(({ value }) => ({
        label: t(`${value}`),
        value,
      })),
    [t],
  );
  const togglePass = useCallback(() => {
    setChangePass((old) => !old);
  }, []);

  const validation = useMemo(
    () => ({
      associateId: formValidators.composeValidators(
        formValidators.required(t('required')),
        formValidators.maxChars(
          t('maxChars', { chars: MAX_ASSOCIATE_ID_CHARS }),
          MAX_ASSOCIATE_ID_CHARS,
        ),
        formValidators.noSpaces(t('noSpaces')),
      ),

      storeNo: formValidators.composeValidators(
        formValidators.required(t('required')),
        formValidators.maxChars(
          t('maxChars', { chars: MAX_STORE_NO_CHARS }),
          MAX_STORE_NO_CHARS,
        ),
      ),
    }),
    [t],
  );

  const initialValuesForAssociate: Partial<User> = useMemo(
    () => ({
      name: associate?.name,
      associateId: associate?.associateId,
      position: associate?.position,
      storeNo: associate?.store?.storeNo,
      inventoryScreenType: associate?.inventoryScreenType,
    }),
    [
      associate?.name,
      associate?.associateId,
      associate?.position,
      associate?.store?.storeNo,
      associate?.inventoryScreenType,
    ],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={associate ? initialValuesForAssociate : {}}
      validate={passwordMatchValidator}
      render={(formRenderProps) => {
        const { handleSubmit, invalid, dirty, values } = formRenderProps;

        const submitDisabled = invalid || inProgress || !dirty;

        const { inventoryScreenType, position } = values;

        return loading ? (
          <Loader color="#007cb9" />
        ) : (
          <form className={classes} onSubmit={handleSubmit}>
            <InputField
              type="text"
              name="name"
              label={messages.associateName}
              required
              validate={required(t('required'))}
              placeholder={messages.associateName}
            />
            <InputField
              type="number"
              name="associateId"
              label={messages.associateId}
              required
              validate={validation.associateId}
              placeholder={messages.id}
            />
            <SelectField
              name="position"
              options={positions}
              label={messages.position}
              validate={required(t('required'))}
              innerLabel={!position && t('selectAnOption')}
            />
            <InputField
              type="number"
              name="storeNo"
              label={messages.storeNumber}
              required
              validate={validation.storeNo}
              placeholder={messages.storeNumber}
            />
            <SelectField
              name="inventoryScreenType"
              options={inventoryStyle}
              label={messages.moveInventoryStyle}
              validate={required(t('required'))}
              innerLabel={!inventoryScreenType && t('selectAnOption')}
            />
            {associateId && (
              <Button
                type="button"
                onClick={togglePass}
                variant="link"
                className="hhsa-manage-users-form__change-pass-link"
              >
                <span>
                  {changePass ? messages.cancel : messages.changePass}
                </span>
              </Button>
            )}

            {(!associateId || changePass) && (
              <>
                <PasswordField
                  name="password"
                  label={messages.password}
                  validate={passwordValidators}
                  required
                  hidePasswordStrength
                  placeholder="********"
                />
                <PasswordField
                  name="confirmPassword"
                  label={messages.confirmNewPassword}
                  validate={passwordValidators}
                  required
                  hidePasswordStrength
                  placeholder="********"
                />
              </>
            )}
            <div className="hhsa-manage-users-form__actions">
              <Button
                type="button"
                onClick={handleCancel}
                variant="outline"
                className="hhsa-manage-users-form__actions__link"
              >
                <span>{messages.cancel} </span>
              </Button>

              <Button
                disabled={submitDisabled}
                type="submit"
                variant="solid"
                className="hhsa-manage-users-form__actions__submit"
              >
                {associateId ? messages.updateAssosiate : messages.addAssociate}
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default ManageUsersForm;
