import React, { useState } from 'react';
import notFoundImage from '../../../public/assets/images/notFound.png';
import { Loader } from 'ncoded-component-library';

import './ProductImage.styles.scss';

type ProductImageProps = {
  img?: string;
  alt?: string;
};

const ProductImage: React.FC<ProductImageProps> = (props) => {
  const { img, alt } = props;
  const [fetching, setFetching] = useState(true);

  return (
    <>
      {fetching && (
        <Loader color="#007cb9" inline className="product-image-loader" />
      )}
      <img
        src={img || notFoundImage}
        onLoad={() => setFetching(false)}
        onError={({ currentTarget }) => {
          setFetching(false);
          currentTarget.src = notFoundImage;
        }}
        alt={alt || 'product-img'}
      />
    </>
  );
};

export default ProductImage;
