import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Checkbox, RadioGroup, Select } from 'ncoded-component-library';
import FilterIcon from 'icons/Filter.icon';
import { TimeFilterValue, TableFilter } from '../../Table.component';
import DateFilter from '../DateFilter';
import Drawer from 'components/Drawer';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import utils from 'utils';

import './Filters.styles.scss';

export type SelectedFilters = {
  [key: string]: string;
};

type FiltersProps = {
  hasTimeFilter?: boolean;
  timeFilterParams?: TimeFilterValue[];
  buttonClassName?: string;
  buttonText?: string;
  tableFilterParams: TableFilter[];
  onApplyFilters: (filters: SelectedFilters) => void;
  onResetFilters?: () => void;
};

const Filters: React.FC<FiltersProps> = (props) => {
  const {
    hasTimeFilter,
    timeFilterParams,
    buttonText,
    buttonClassName,
    tableFilterParams,
    onApplyFilters,
    onResetFilters,
  } = props;

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({});

  const handleFilterChange = useCallback((name: string, value: string) => {
    setSelectedFilters((prev) => ({ ...prev, [name]: value }));
  }, []);

  const filterElements = useMemo(
    () =>
      tableFilterParams.map(({ innerLabel, name, selectOptions, type }) => {
        switch (type) {
          case 'radio':
            return (
              <div className="filters__filter" key={name}>
                <label className="filters__filter__label">{innerLabel}</label>
                <RadioGroup
                  name={name}
                  options={selectOptions}
                  value={selectedFilters[name]}
                  onChange={(ev) => {
                    const newValue = ev.currentTarget.value;

                    handleFilterChange(name, newValue);
                  }}
                />
              </div>
            );

          case 'checkbox':
            return (
              <div className="filters__filter" key={name}>
                <label className="filters__filter__label">{innerLabel}</label>
                <div className="checkbox-group">
                  {selectOptions?.map(({ label, value }) => (
                    <Checkbox
                      key={value}
                      label={label}
                      name={value}
                      checked={selectedFilters[name]?.includes(value)}
                      onChange={() => {
                        const newValue = utils.handleQueryArrayParam(
                          selectedFilters[name],
                          value,
                        );

                        handleFilterChange(name, newValue);
                      }}
                    />
                  ))}
                </div>
              </div>
            );

          case 'select':
            return (
              <div className="filters__filter" key={name}>
                <label className="filters__filter__label">{innerLabel}</label>
                <Select
                  key={selectedFilters[name]}
                  name={name}
                  options={selectOptions}
                  value={selectedFilters[name]}
                  className="hhsa-dropdown-select"
                  searchable
                  onChange={(option: OptionValue<any>) => {
                    handleFilterChange(name, option.value);
                  }}
                />
              </div>
            );

          default:
            return <></>;
        }
      }),
    [handleFilterChange, selectedFilters, tableFilterParams],
  );

  useEffect(() => {
    tableFilterParams?.forEach(({ name, selectValue }) => {
      setSelectedFilters((prev) => ({ ...prev, [name]: selectValue }));
    });
    timeFilterParams?.forEach(({ name, value }) => {
      setSelectedFilters((prev) => ({ ...prev, [name]: value }));
    });
  }, [tableFilterParams, timeFilterParams]);

  const buttonClasses = classNames('filters__button', buttonClassName);

  const tempFilters = useRef(null);

  return (
    <div className="filters">
      <Button
        className={buttonClasses}
        onClick={() => {
          setOpen(true);
          tempFilters.current = selectedFilters;
        }}
        icon={<FilterIcon />}
      >
        {buttonText || t('filters')}
      </Button>

      <Drawer
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedFilters(tempFilters.current);
        }}
        headerItems={<div>{t('filters')}</div>}
        actionButtons={
          <>
            {onResetFilters && (
              <Button
                variant="outline"
                onClick={() => {
                  setOpen(false);
                  onResetFilters();
                  setSelectedFilters({});
                }}
              >
                {t('resetFilters')}
              </Button>
            )}
            <Button
              variant="solid"
              onClick={() => {
                setOpen(false);
                onApplyFilters(selectedFilters);
              }}
              className="hhsa-table__add"
            >
              {t('apply')}
            </Button>
          </>
        }
      >
        <main className="filters__main">
          {filterElements}

          {hasTimeFilter && (
            <DateFilter
              selectedFilters={selectedFilters}
              onFilterChange={handleFilterChange}
            />
          )}
        </main>
      </Drawer>
    </div>
  );
};

export default Filters;
