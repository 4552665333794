import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { SubmissionErrors } from 'final-form';
import { Button } from 'ncoded-component-library';
import { Form, FormProps } from 'react-final-form';
import formValidators from 'utils/formValidators';
import { useTranslation } from 'react-i18next';
import PasswordField from 'components/PasswordField';
import regex from 'constants/regex';
import { ResetPasswordFormValues } from 'models/AuthBody';
import './ResetPasswordForm.styles.scss';
import './ResetPasswordForm.styles.responsive.scss';

const { MIN_LENGTH } = regex;

type ResetPasswordFormProps = {
  className?: string;
  onSubmit: (
    values: ResetPasswordFormValues,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  initialValues: Partial<ResetPasswordFormValues>;
  inProgress: boolean;
} & FormProps<ResetPasswordFormValues>;

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
  const { className, initialValues, onSubmit, inProgress } = props;

  const { t } = useTranslation();

  const classes = classNames('hhsa-reset-password-form', className);

  const messages = useMemo(
    () => ({
      changePasswordSubtitle: t('General.changePasswordSubtitle'),
      changePassword: t('General.changePassword'),
      newPassword: t('General.newPassword'),
      confirmNewPassword: t('General.confirmNewPassword'),
      passwordMismatch: t('General.passwordMismatch'),
      backToLogin: t('General.backToLogin'),
      passwordDescription: t('General.passwordDescription', {
        minLength: MIN_LENGTH,
      }),
    }),
    [t],
  );

  const passwordValidators = useMemo(
    () => formValidators.getPasswordValidators(t),
    [t],
  );

  const passwordMatchValidator = useCallback(
    (values: ResetPasswordFormValues) => {
      const errors: Partial<ResetPasswordFormValues> = {};

      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = messages.passwordMismatch;
      }

      return errors;
    },
    [messages.passwordMismatch],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={passwordMatchValidator}
      render={(formRenderProps) => {
        const { handleSubmit, invalid } = formRenderProps;

        const submitDisabled = invalid || inProgress;

        return (
          <form className={classes} onSubmit={handleSubmit}>
            <span className="hhsa-reset-password-form__subtitle">
              {messages.changePasswordSubtitle}
            </span>

            <PasswordField
              name="password"
              hideIcon
              label={messages.newPassword}
              placeholder={messages.newPassword}
              validate={passwordValidators}
              hidePasswordStrength
            />
            <PasswordField
              name="confirmPassword"
              hideIcon
              label={messages.confirmNewPassword}
              placeholder={messages.confirmNewPassword}
              validate={passwordValidators}
              hidePasswordStrength
            />

            <Button
              type="submit"
              disabled={submitDisabled}
              className="hhsa-reset-password-form__submit"
            >
              {messages.changePassword}
            </Button>
          </form>
        );
      }}
    />
  );
};

export default ResetPasswordForm;
