import { authPath } from 'api/auth';
import httpClient from '../httpClient';
import { User } from 'models/User';

const getUserProfile = () => httpClient.get<User>(authPath`current`);

const updatePersonalInformation = (updatedUser: Partial<User>) => {
  return httpClient.patch<User>(authPath``, updatedUser);
};

export default {
  getUserProfile,
  updatePersonalInformation,
};
