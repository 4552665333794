import React from 'react';
import classNames from 'classnames';

import './Drawer.styles.scss';
import './Drawer.styles.responsive.scss';

interface DrawerProps {
  className?: string;
  open: boolean;
  anchor?: 'left' | 'right';
  onClose: () => void;
  children: React.ReactNode;
  headerItems?: React.ReactNode;
  actionButtons?: React.ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({
  className,
  open,
  onClose,
  headerItems,
  children,
  actionButtons,
  anchor = 'right',
}) => {
  const overlayClasses = classNames('hhsa-overlay', {
    'hhsa-overlay--open': open,
    'hhsa-overlay--hidden': !open,
  });

  const drawerClasses = classNames(
    className,
    'hhsa-drawer',
    `hhsa-drawer--${anchor}`,
    {
      'hhsa-drawer--animate': open,
      'hhsa-drawer--hidden': !open,
    },
  );

  return (
    <>
      <div className={overlayClasses} onClick={onClose} aria-hidden="true" />
      <div tabIndex={-1} className={drawerClasses}>
        {headerItems && (
          <header className="hhsa-drawer__header">{headerItems}</header>
        )}
        <main className="hhsa-drawer__content">{children}</main>
        {actionButtons && (
          <div className="hhsa-drawer__actions">{actionButtons}</div>
        )}
      </div>
    </>
  );
};

export default Drawer;
